import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../Common/BaseUrl";

import { useHistory } from "react-router-dom";
import axios from "axios";
// import Switch from "react-switch";
import moment from "moment";
import LeftTab from "../Common/LeftTab";
import ChangePassword from "./ChangePassword";
const meta = {
  title: "Profile | ITOEX  Exchange",
  description:
    "ITOEX  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://ITOEX  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Profile = (props) => {

  const history = useHistory();



  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [kycStatus, setKycStatus] = useState("N");
  const [type, setType] = useState("password");
  const [OldPassword, setOldPassword] = useState("");
  const [OldPassError, setOldPassError] = useState("");
  const [type1, setType1] = useState("password");
  const [NewPassword, setNewPassword] = useState("");
  const [NewPasswordError, setNewPasswordError] = useState("");
  const [type2, setType2] = useState("password");
  const [CNewPasswordError, setCNewPasswordError] = useState("");
  const [btnDisableOP, setBtnDisableOP] = useState(false);
  const [btnDisableNP, setBtnDisableNP] = useState(false);
  const [btnDisableCNP, setBtnDisableCNP] = useState(false);
  const [image, setImage] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [qrcodeurl, setQrcodeurl] = useState("assets/img/qr-dummy.png");
  const [SKey, setSKey] = useState("");
  const [checkTwoFa, setCheckTwoFa] = useState("");
  const [vcode, setVcode] = useState("");
  const [TwoFaText, setTwoFaText] = useState("");
  const [message, setMessage] = useState("");
  const [isBlur, setIsBlur] = useState(true);
  const [profileImg, setprofileImg] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUserDetails();
    LoginActivity();
    generateGoogleCode();
  }, []);
  // const saveFile = async (e) => {
  //   e.preventDefault();
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };
  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);

  //   try {
  //     const res = await axios.put(
  //       baseUrl + "/frontapi/update-profile-image",
  //       formData,
  //       config
  //     );

  //   } catch (ex) {
  //   }
  // };
  const getUserDetails = () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setFirstName(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        setEmail(resp.data[0].email);
        setMobile_number(resp.data[0].mobile_no);
        setKycStatus(resp.data[0].kycVerification_status);
        setImage(resp.data[0].profile_image);
        if (
          resp.data[0].enable_2fa == "N" ||
          resp.data[0].enable_2fa == "null"
        ) {
          setTwoFaText("Enable");
        }
        if (resp.data[0].enable_2fa == "Y") {
          setTwoFaText("Disable");
        }
        setCheckTwoFa(resp.data[0].enable_2fa);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") != "null" &&
          localStorage.getItem("token") !== null
        ) {
          toast.error(resp.message);
          localStorage.clear();
          setTimeout(() => this.props.history.push("/login"), 2000);
        }
      }
    });
  };

  const generateGoogleCode = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/generateGoogleCode", {}, config)
      .then((resp1) => {
        var resp = resp1.data;
        setQrcodeurl(resp.data.qrCodeUrl);
        setSKey(resp.data.secretKey);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var regex =
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

    if (!OldPassword && !NewPassword && !CPassword) {
      setOldPassError("This field is required");
      setNewPasswordError("This field is required");
      setCNewPasswordError("This field is required");
      return false;
    }

    if (OldPassword == "" || OldPassword == undefined) {
      setOldPassError("This field is required");
      return false;
    }
    if (NewPassword == "" || NewPassword == undefined) {
      setNewPasswordError("This field is required");
      return false;
    }

    if (!NewPassword.match(regex)) {
      setNewPasswordError(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    } else {
      setNewPasswordError("");
    }
    if (CPassword == "" || CPassword == undefined) {
      setCNewPasswordError("This field is required");
      return false;
    }

    if (NewPassword !== CPassword) {
      toast.error("Password mis-matched");
      return false;
    }

    // Updating Password //

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      NewPassword: NewPassword,
      OldPassword: OldPassword,
      CPassword: CPassword,
    };

    axios
      .post(baseUrl + "/frontapi/changePassword", LoginData, config)
      .then((resp) => {
        var response = resp.data;
        setNewPassword("");
        setOldPassword("");
        setCPassword("");
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login-to-buy-sell-crypto";
          }, 1000);
          return false;
        } else {
          toast.dismiss();
          toast.error(response.message);
          return false;
        }
      });
  };

  const handleClick = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const handleClick1 = () => {
    if (type1 === "text") {
      setType1("password");
    } else {
      setType1("text");
    }
  };

  const handleClick2 = () => {
    if (type2 === "text") {
      setType2("password");
    } else {
      setType2("text");
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name == "OldPassword") {
      var OldPassword = event.target.value;
      var OldPassError = "";
      if (!OldPassword) {
        toast.dismiss();
        OldPassError = "This field is required";
        setOldPassError(OldPassError);
        setBtnDisableOP(false);
        setOldPassword(OldPassword);
        return false;
      } else {
        setOldPassError("");
        setBtnDisableOP(true);
        setOldPassword(OldPassword);
      }
    }

    if (name == "NewPassword") {
      var NewPasswordd = event.target.value;
      var NewPasswordError = "";
      if (!NewPassword) {
        toast.dismiss();
        NewPasswordError = "This field is required";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        setNewPassword(NewPasswordd);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      if (NewPassword.length < 7) {
        toast.dismiss();
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);

        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-8])(?=.*[!@#$%^&*]).{7,}$";
      if (!NewPassword.match(regex)) {
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
    }

    if (name == "CPassword") {
      var CPassword = event.target.value;
      var CNewPasswordError = "";
      if (!CPassword) {
        toast.dismiss();
        CNewPasswordError = "This field is required";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
      if (CPassword != NewPassword) {
        toast.dismiss();
        CNewPasswordError = "Confirm password does not matched";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        // return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
    }
    if (name === "vcode") {
      const value = event.target.value
      setMessage("");

      console.log(value)


      const onlyNumbersRegex = /^[0-9]*$/;
      // Check if the input has exactly 10 digits
      const tenDigitsRegex = /^\d{6}$/;

      if (!onlyNumbersRegex.test(value)) {
        // setMobileError("Only numbers are allowed");
        return;
      }


      if (value.length < 7) {
        setVcode(event.target.value);
      }





      if (!tenDigitsRegex.test(value) && value.length < 7 && value.length !== 0) {


        this.setMessage("Please Enter a valid code");
        return;
      }
      else {
        this.setMessage("");

      }
    }
  };

  const verifyCode = () => {
    if (!vcode) {
      setMessage("This field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let faData = {
      code: vcode,
      TwoFaText: TwoFaText,
    };
    axios
      .post(baseUrl + "/frontapi/verifyGoogleCode", faData, config)
      .then((resp) => {
        setIsBlur(false);
        // setMessage(resp.data.message);
        // "code verified"
        if (resp.data.status) {
          toast.success(resp.data.message);
        } else {
          toast.error(resp.data.message);
        }
        getUserDetails();
      });
  };

  const LoginActivity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTabledata(respNew.findData);
        setRespStatus(respNew.status);
      }
    });
  };

  const showTableHtml = () => {
    if (respStatus === true) {
      const html = [];
      var snno = 0;
      tabledata.map(function (value, i) {
        snno++;
        html.push(
          <tr>
            <td> {snno} </td>
            <td>{moment(value.createdAt).format("lll")}</td>
            <td>{value.event}</td>
            <td>{value.device}</td>
            <td>{value.browser}</td>
            <td>{value.osName}</td>
            <td>{value.ip}</td>
            <td>
              {value.cityName}, {value.countryName}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  const updateProfileImage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .post(baseUrl + "/frontapi/update-profile-image", formData, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };
  const deleteProfileImage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/delete-profile-image", {}, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const deleteAccount = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/delete-account", {}, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const maxCharacters = 19;
  const truncatedEmail =
    email && email.length > maxCharacters
      ? email.slice(0, maxCharacters) + "..."
      : email;

  console.log(truncatedEmail, "truncatedEmail")
  return (
    <div>
      <DocumentMeta {...meta}>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container container2">
              <LeftTab />
              <div className="google-auth ">
                <div className="row">
                  <div className="auth-left col-lg-3 mb-3">
                    <div className="white_box h_100 mb-0">
                      <div className="user_profile">
                        {image == "" || image == undefined || image == null ? (
                          <img
                            src="/assets/img/user_profile.png"
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={baseUrl + "/static/profile/" + image}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                        <div className="edit-profile position-absolute">
                          <input
                            type="file"
                            // ref={myref}
                            id="img1"
                            style={{ display: "none" }}
                            onChange={updateProfileImage}
                          />

                          <b class="trash-icon"><i onClick={deleteProfileImage} style={{ color: "red" }} className="fa fa-trash"></i></b>
                          <label htmlFor="img1" className="editlabel">
                            <i className="fa fa-edit"></i>
                          </label>



                        </div>
                      </div>
                      <h5 className="user text-center mt-3 mb-5">
                        {firstName} {lastName == "null" ? "" : lastName}
                      </h5>

                      <div className="profile-info info-main-outer ">
                        <ul>
                          <li className="user row mb-2">
                            <span className="col-auto">Gmail:</span>
                            <span className="col-auto ml-auto" title={email}>
                              {truncatedEmail ? (`${truncatedEmail}`
                              ) : (
                                <button
                                  style={{
                                    marginLeft: "10px",
                                    padding: "5px 10px",
                                    backgroundColor: "#007BFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push("/kyc");
                                  }}
                                >
                                  Add Now
                                </button>

                              )}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">Mobile No.:</span>
                            <span className="col-auto ml-auto">
                              {mobile_number ? (`${mobile_number}`
                              ) : (
                                <button
                                  style={{
                                    marginLeft: "10px",
                                    padding: "5px 10px",
                                    backgroundColor: "#007BFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push("/kyc");
                                  }}
                                >
                                  Add Now
                                </button>

                              )}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">KYC:</span>
                            <span className="col-auto ml-auto">
                              {kycStatus === "Y" ? "Completed" : "Pending"}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">Delete Account:</span>
                            <span className="col-auto ml-auto">
                              <button
                                className="btn btn-danger"
                                onClick={handleShow}
                              >
                                Delete
                              </button>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ChangePassword />
                  {/* <div className="col-lg-4 mb-3">
                  <div className="white_box h_100 mb-0">
                    <h5>Change Password</h5>
                    <div className="bank-info info-main-outer mt-2">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group eye_pass eye_pass_two">
                              <label>Old Password</label>
                              <input
                                type={type}
                                className="form-control"
                                name="OldPassword"
                                value={OldPassword}
                                onChange={handleChange}
                                placeholder="Enter Old Password"
                              />
                              <span
                                className="password__show eye1"
                                onClick={handleClick}
                              >
                                {type === "text" ? (
                                  <i className="las la-eye"></i>
                                ) : (
                                  <i className="las la-low-vision"></i>
                                )}
                              </span>

                              {OldPassError && (
                                <div className="errorMsg">
                                  <span style={{ color: "red" }}>
                                    {OldPassError}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group eye_pass eye_pass_two">
                              <label>New Password</label>
                              <input
                                type={type1}
                                className="form-control"
                                name="NewPassword"
                                value={NewPassword}
                                onChange={handleChange}
                                placeholder="Enter New Password"
                              />
                              <span
                                className="password__show eye1"
                                onClick={handleClick1}
                              >
                                {type1 === "text" ? (
                                  <i className="las la-eye"></i>
                                ) : (
                                  <i className="las la-low-vision"></i>
                                )}
                              </span>

                              {NewPasswordError && (
                                <div className="errorMsg">
                                  <span style={{ color: "red" }}>
                                    {NewPasswordError}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group eye_pass eye_pass_two">
                              <label>Confirm New Password</label>
                              <input
                                type={type2}
                                className="form-control"
                                name="CPassword"
                                value={CPassword}
                                onChange={handleChange}
                                placeholder="Enter Confirm Password"
                              />
                              <span
                                className="password__show eye1"
                                onClick={handleClick2}
                              >
                                {type2 === "text" ? (
                                  <i className="las la-eye"></i>
                                ) : (
                                  <i className="las la-low-vision"></i>
                                )}
                              </span>
                              {CNewPasswordError && (
                                <div className="errorMsg">
                                  <span style={{ color: "red" }}>
                                    {CNewPasswordError}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <button
                              className="btn btn_man fs14"
                            // disabled={
                            //   !btnDisableOP ||
                            //   !btnDisableNP ||
                            //   !btnDisableCNP
                            // }
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}

                  <div className="auth-left col-lg-5 mb-3">
                    <div className="white_box h_100 mb-0">
                      <h5>Google Authentication</h5>
                      <div className="google-auth">
                        <div className="auth-left mb-3">
                          <div
                            className={
                              isBlur == true
                                ? "qr-outer kyc_bg qr_load"
                                : "qr-outer kyc_bg"
                            }
                          >
                            <img
                              className="img-fluid"
                              src={qrcodeurl}
                              alt="qrCode"
                            />
                          </div>
                        </div>

                        <div className="auth-right">
                          <h5 className="kyc_h5">
                            Key :<span> {SKey}</span>{" "}
                          </h5>

                          <h5>
                            Status: {checkTwoFa == "Y" ? "Enabled" : "Disabled"}
                          </h5>
                          <label className="fw-500">Authentication Code</label>
                          <div className="row">
                            <div className=" col-7 pr-0">
                              <input
                                type="text"
                                className="form-control"
                                name="vcode"
                                placeholder="Enter Code"
                                value={vcode}
                                onChange={handleChange}
                              />
                            </div>
                            <div className=" col-5">
                              <button
                                onClick={verifyCode}
                                className="btn btn_man w100px"
                              >
                                {TwoFaText}
                              </button>
                            </div>
                          </div>

                          <p
                            className="mt-0"
                            // style={
                            //   message == " code verified  "
                            //     ? { color: "green" }
                            //     : { color: "red" }
                            // }
                            style={{ color: "red" }}
                          >
                            {message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <h5>Your Recent Activity</h5>
            <div className="white_box fs12 ">
              <div className="scrool_wallet_box">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th> Sr No</th>
                      <th>Date (IST) </th>
                      <th>Event</th>
                      <th>Device</th>
                      <th>Browser</th>
                      <th>Operation System</th>
                      <th>IP</th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody>{showTableHtml()}</tbody>
                </table>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Woohoo, Are you sure you want to delete your account?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={deleteAccount}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default Profile;
