import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class SpotSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,
      spotTab: "",
      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: [],
      showData: [],
      totalBalance: 0,
      defaultValue: "",
      button: "Buy",
      selectedCoin: "",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login-to-buy-sell-crypto";
    }

    this.spotList();
    this.WalletAllCoinBalance();
  }

  spotList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/coinDeposite", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        console.log(resp,"ya ya")

        if (resp.status) {
          this.setState({
            allData: resp.data,
            trasactionList: resp.data.main_wallet,
            cryptoIdWallet: resp.data.coin_id,
            TransactionsStatus: resp.status,
            defaultValue: resp.data[0].walletSum[0].walletAmount,
            defaultWalletTable: resp.data[0].wallet, //,spotList: resp.data.Spot_wallet,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((res) => {
        const resp = res.data;

        console.log(resp)
        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };
  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ selectedCoin: value });
  };
  showTableHtml = (value) => {
    if (
      this.state.TransactionsStatus === true &&
      Object.keys(this.state.showData).length > 0
    ) {
      const html = [];
      const filteredTransactions =
        value &&
        value.wallet &&
        value.wallet.filter((e) => e.name === this.state.selectedCoin);
      if (filteredTransactions.length > 0) {
        filteredTransactions.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>{e.amount}</td>
              <td>{e.type  }</td>
              <td className="rewidth_t">{e.hash}</td>
              <td>{e.chain_symbol}</td>
              <td>{e.created_at}</td>
              <td>
                {e.status === "reject" ? (
                  <span>
                    Reject{" "}
                    <Popup
                      trigger={<i className="fa fa-info-circle"></i>}
                      position="right center"
                    >
                      <div>{e.reject}</div>
                    </Popup>
                  </span>
                ) : e.status === "completed" ? (
                  <span> Completed </span>
                ) : (
                  "Pending"
                )}
              </td>
            </tr>
          );
        });
      } else if (!this.state.selectedCoin) {
        value &&
          value.wallet &&
          value.wallet.forEach((e, i) => {
            html.push(
              <tr>
              <td>{i + 1}</td>
              <td>{e.amount}</td>
              <td>{e.type  }</td>
              <td className="rewidth_t">{e.hash}</td>
              <td>{e.chain_symbol}</td>
              <td>{e.created_at}</td>
                <td>
                  {e.status === "reject" ? (
                    <span>
                      Reject{" "}
                      <Popup
                        trigger={<i className="fa fa-info-circle"></i>}
                        position="right center"
                      >
                        <div>{e.reject}</div>
                      </Popup>
                    </span>
                  ) : e.status === "completed" ? (
                    <span> Completed </span>
                  ) : (
                    "Pending"
                  )}
                </td>
              </tr>
            );
          });
      } else {
        return (
          <tr>
            <td colSpan={8} className="text-center">
              No Data Found
            </td>
          </tr>
        );
      }
      return <tbody className="">{html}</tbody>;
    } else {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  ButtonChange = () => {
    this.setState({ button: "Buy" });
  };

  ButtonChange1 = () => {
    this.setState({ button: "Sell" });
  };

  renderCoinList = (allData) => {
    let { mainWallet } = this.state;
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li key={index} onClick={() => this.OpenMain(item)}>
          <span className={active ? "active" : <></>}>{item.name}</span>
        </li>
      );
    });
  };
  render() {
    const meta = {
      title: `Deposit Transactions | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { showData, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  <div className="white_box">
                    <div className="row">
                      <div className="col-md-8 col-lg-9">
                        <ul className="nav nav-tabs2 mb-3 ">
                          <li className="nav-item">
                            <Link to="/transactions"> Main Wallet </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/depositeHistory"> Spot </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinDeposite" className="active">
                              {" "}
                              Deposit Coin{" "}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinFundRequest"> Withdrawal </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <select
                          className="form-control"
                          value={this.state.selectedCoin}
                          onChange={this.handleChange}
                        >
                          <option value={""}>Select Crypto</option>
                          {allData.length > 0 &&
                            allData[0].coinList.map((item) => {
                              return (
                                <option value={item.short_name}>
                                  {item.short_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {/* <ul className="nav nav-tabs offer_beu spot">
                      {this.renderCoinList(allData || [])}
                    </ul> */}

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className=" top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Amount</th>
                                      <th>Transaction Type</th>
                                      <th>Token ID</th>
                                      <th>Currency</th>
                                      <th>Created</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  {this.showTableHtml(this.state.showData)}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane " id="Transactions">
                        <div className="tab-content">
                          <div className="tab-pane active " id="Ledger"></div>
                          <div className="tab-pane" id="Transactions2">
                            <div className="wallet_box">
                              <div className="table-responsive">
                                <table className="table ">
                                  <thead>
                                    <tr>
                                      <th>Created</th>
                                      <th>Deposit</th>
                                      <th>Withdraw</th>
                                      <th className="tr">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* {this.showTableHtml()} */}
                                    <tr>
                                      <td>Nov 20, 2020</td>
                                      <td>200</td>
                                      <td>Description </td>
                                      <td className="tr">0</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>
          </div> */}
          </div>

          <Footer />
        </div>
      </DocumentMeta>
    );
  }
}

export default SpotSell;
