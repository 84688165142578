import "./loader.css"


const Loader_dvsn = () => {
    return (
      <div 
        style={{
          display: "flex", 
          justifyContent: "center", // Centers horizontally
          alignItems: "center", // Centers vertically
          overflow: 'hidden'
        }}
      >
        <svg
          viewBox="25 25 50 50"
          style={{
            width: "50px", // Optional: Adjust the size of the loader
            height: "50px", // Optional: Adjust the size of the loader
          }}
        >
          <circle r="20" cy="50" cx="50" stroke="gray" strokeWidth="5" fill="none"></circle>
        </svg>
      </div>
    );
  };

  export default Loader_dvsn