import React, { useEffect, useRef, useState } from "react";
import * as myConstList from "../../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import { EmailValidation } from "../../Validation/EmailValidation";
import { ProductName } from "../../Common/ProductName";
import DocumentMeta from "react-document-meta";

const baseUrl = myConstList.baseUrl;
function ForgotForm() {
  const counterRef = useRef(null);
  const [codeMatchedStatus, setCodeMatchedStatus] = useState(false);
  const [timerText, setTimerText] = useState("");
  const [counter, setCounter] = useState("");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordIssue, setPasswordIssue] = useState("");
  const [cPasswordIssue, setCPasswordIssue] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [countryCode, setCountryCode] = useState("+");
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [automateCountryCode, setAutomateCountryCode] = useState("ae");

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "ae"
    );
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();
    if (passwordIssue || cPasswordIssue) {
      return false;
    }
    if (!password) {
      setPasswordIssue("This field is required");
      return false;
    }
    if (!cPassword) {
      setCPasswordIssue("This field is required");
      return false;
    }

    let params = {
      Password: password,
      CPassword: cPassword,
    };
    if (email) {
      params.email = email;
    }
    if (mobile) {
      params.mobile = mobile;
    }

    axios.post(baseUrl + "/frontapi/forgotPassword", params).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        toast.dismiss();
        toast.success(resp.message);
        setCPassword("");
        setPassword("");
        setTimeout(function () {
          window.location = "/login-to-buy-sell-crypto";
        }, 2000);
      }
      if (resp.status === false) {
        toast.dismiss();
        toast.error(resp.message);
      }
    });
  };
  const handleMobileSubmit = (e) => {
    e.preventDefault();
    if (!mobile && !email) {
      setMobileError("This field is required");
      return false;
    }
    if (!verificationCode) {
      setVerificationCodeError("This field is required");
      return false;
    }
    if (mobileError || emailError) {
      return false;
    }
    let data;
    if (selectedFiledType === "mobile") {
      data = {
        mobile: mobile,
        page: "forgot",
        verification_code: verificationCode,
        selectedFiledType: selectedFiledType,
        countryCode: countryCode,
      };
    }
    if (selectedFiledType === "email") {
      data = {
        email: email,
        page: "forgot",
        verification_code: verificationCode,
        selectedFiledType: selectedFiledType,
      };
      if (
        !new RegExp(/^[a-z0-9][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$/i).test(
          email
        )
      ) {
        setEmailError("Please enter a valid email address");
        return false;
      }
    }
    axios.post(baseUrl + "/frontapi/codeVerify", data).then((res) => {
      const resp = res.data;
      if (resp.status) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
      setCodeMatchedStatus(resp.status);
    });
  };
  const startCountdown = (seconds) => {
    let counter = seconds;
    counterRef.current = setInterval(() => {
      setCounter(counter);
      setTimerText("Resend In");
      setBtnDisable(true);
      counter--;
      if (counter < 0) {
        clearInterval(counterRef.current);
        setTextForCode("Resend Code");
        setBtnDisable(false);
        setTimerText("");
      }
    }, 1000);
  };

  const handlecodechange = async (e) => {
    let { value } = e.target;



    const onlyNumbersRegex = /^[0-9]*$/;
    // Check if the input has exactly 10 digits
    const tenDigitsRegex = /^\d{6}$/;

    if (!onlyNumbersRegex.test(value)) {
      // setMobileError("Only numbers are allowed");
      return;
    }


    if (value.length < 7) {
      setVerificationCode(value);
    }

    console.log(value)



    if (!tenDigitsRegex.test(value) && value.length < 7 && value.length !== 0) {
      setVerificationCodeError("Please Enter a valid otp");
      return;
    }
    else {
      setVerificationCodeError("");
    }



    if (!value) {
      setVerificationCodeError("This field is required");
      return;
    }


  };
  const getCode = async () => {
    if (!email && !mobile) {
      setEmailError("This field is required");
      return false;
    }
    let registerData = {
      countryCode: countryCode,
      page: "forgot",
      selectedFiledType: selectedFiledType,
    };

    if (selectedFiledType === "email") {
      registerData.email = email;
      if (
        !new RegExp(
          /^[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
        ).test(email)
      ) {
        setEmailError("Please enter a valid email address");

        return false;
      }
    }
    if (selectedFiledType === "mobile") {
      registerData.mobile = mobile;

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(countryCode)) {
        setMobileError("Please enter a valid country code");
        return;
      }

      const isValidLength = mobile.length >= 5 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileError("Please enter a valid mobile number");
        return false;
      } else {
        setMobileError("");
      }
    }

    await axios
      .post(baseUrl + "/frontapi/getcode", registerData)
      .then((res) => {
        const resp = res.data;
        setVerificationCodeError("");

        if (resp.status === true) {
          toast.success(resp.message);
          startCountdown(30);
          setButtonDisableVerification(false);
          return false;
        }
        if (resp.status === false) {
          setButtonDisableVerification(true);
          toast.error(resp.message);
          return false;
        }
      });
  };
  const handleChange101 = (event) => {
    let { name, value } = event.target;
    // Password //
    if (name === "Password") {
      setPassword(value);
      var passwordError = PasswordValidation(name, value);
      setPasswordIssue(passwordError);
      if (passwordError == "mismatched") {
        setPasswordIssue("");
        setCPasswordIssue("Password mis-matched");
      }
      if (passwordError == "") {
        setPasswordIssue("");
        setCPasswordIssue("");
      }
    }

    if (name == "CPassword") {
      setCPassword(value);
      var CpasswordError = PasswordValidation(name, value);
      setCPasswordIssue(CpasswordError);

      if (passwordError == "") {
        setCPasswordIssue("");
      }
    }
  };

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;

    setEmailError("");
    setEmail("");
    if (name === "phone") {
      const onlyNumbersRegex = /^[0-9]*$/;
      // Check if the input has exactly 10 digits
      const tenDigitsRegex = /^\d{10}$/;

      if (!onlyNumbersRegex.test(value)) {
        // setMobileError("Only numbers are allowed");
        return;
      }

      if (value[0] >= 6) {
        if (value.length < 11) {
          setMobile(value);
        }
      }
      else {
        setMobile("");
        setMobileError("This field is required");
        return;
      }



      if (!tenDigitsRegex.test(value) && value.length < 11 && value.length !== 0) {
        setMobileError("Please Enter a valid Mobile Number");
        return;
      }



      if (!value) {
        setMobileError("This field is required");
        return;
      }

      setEmailError("");
      setMobileError("");

      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }
    }

    if (name === "countryCode") {
      if (!value || value.length < 2) {
        setCountryCode("+");
        setMobileError("Country code is required");
        return;
      }

      setCountryCode(value);

      setMobileError("");
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "Email") {
      if (textForCode === "Resend Code") {
        setTextForCode("Get Code");
      }
      // const isEmail = /\S+@\S+\.\S+/.test(value);
      // const isMobile = /^\d+$/.test(value);
      // if (isMobile) {
      //   setSelectedFiledType("mobile");
      //   setInputMobile(value);
      //   setMobile(value);
      //   setEmailError("");
      //   return;
      // } else if (isEmail) {
      setSelectedFiledType("email");
      // }
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      setMobileError("");
    }
  };

  const switchButton = (value) => {
    setMobile("");
    setEmail("");
    setCountryCode("+91");
    setEmailError("");
    setMobileError("");
    if (counterRef.current) {
      clearInterval(counterRef.current);
      setTextForCode("Get Code");
      setBtnDisable(false);
      setTimerText("");
      setCounter("");
    }
    if (value === "email") {
      setSelectedFiledType("email");
    }

    if (value === "mobile") {
      setSelectedFiledType("mobile");
    }
  };

  const meta = {
    title: "Low Cost Crypto Exchange App| Bitcoin Exchange",
    description: `From involving a large number of coins to executing voluminous orders, this platform takes care of everything. ${ProductName} Live Does The Buying And Selling Of Crypto Coin.`,
    canonical: `https://${ProductName}.com/login-to-buy-sell-crypto`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Exchange, Crypto Buying Selling, Buy Sell Bitcoin, BTC Exchange",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <div className="register-main-outer position-relative ">
          <span className="main-bg fadeInLeft animated"></span>
          <div className="container">
            <div className="register-inner">
              <div className="row">
                <div className="col-md-5 col-12 p-0 m-auto">
                  <div className="register-right fadeInRight animated ">
                    <div className="heading-link-outer">
                      <div className="heading d-flex mb-2 align-items-center">
                        <h4 className="">Forgot Password</h4>
                        {!codeMatchedStatus && (
                          <div className="login_tab d-inline-flex ml-auto">
                            <a
                              className={selectedFiledType === "email" ? "active" : ""}
                              onClick={() => switchButton("email")}
                            >
                              Email
                            </a>
                            <a
                              className={selectedFiledType === "mobile" ? "active" : ""}
                              onClick={() => switchButton("mobile")}
                            >
                              Mobile
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="register-form">
                      {!codeMatchedStatus ? (
                        <form onSubmit={handleMobileSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group position-relative">
                                {selectedFiledType === "email" ? (
                                  <>
                                    <label className="form-label">Email</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      placeholder="Enter Email Address"
                                      name="Email"
                                      value={email}
                                      maxLength="64"
                                      onChange={handleChangeEmail}
                                      disabled={counter == 0 ? false : true}
                                      onPaste={(e) => e.preventDefault()}
                                      autoFocus
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label className="form-label">
                                      Mobile Number
                                    </label>

                                    <div className="row">
                                      <div className="col-3">
                                        <input
                                          className="form-control"
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Country Code"
                                          name="countryCode"
                                          autoFocus
                                          value={countryCode}
                                          onChange={handleChangeMobile}
                                          disabled
                                        />
                                      </div>
                                      <div className="col-9 pl-0">
                                        <input
                                          className="form-control"
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Enter Mobile No."
                                          name="phone"
                                          autoFocus
                                          value={mobile}
                                          onChange={handleChangeMobile}
                                          disabled={counter == 0 ? false : true}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                {mobileError && (
                                  <div style={{ color: "red" }} name="mobile">
                                    {mobileError}
                                  </div>
                                )}
                                {emailError && (
                                  <div style={{ color: "red" }} name="email">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Verification Code
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Verification Code"
                                    name="VerificationCode"
                                    onChange={handlecodechange}
                                    disabled={
                                      codeMatchedStatus ||
                                      buttonDisableVerification
                                    }
                                    value={verificationCode}
                                    aria-label="Input group example"
                                    aria-describedby="btnGroupAddon"
                                  />

                                  <div className="input-group-prepend">
                                    <button
                                      className="btn btn_man"
                                      id="btnGroupAddon"
                                      type="button"
                                      onClick={getCode}
                                      disabled={codeMatchedStatus || btnDisable}
                                    >
                                      {timerText}
                                      {"  "}
                                      {counter == 0 ? textForCode : counter}
                                    </button>
                                  </div>
                                </div>
                                <div style={{ color: "red" }}>
                                  {verificationCodeError}
                                </div>
                              </div>
                            </div>
                            {!codeMatchedStatus && (
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <button
                                    className="btn btn_man"
                                    type="submit"
                                    onClick={handleMobileSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </form>
                      ) : (
                        <form onSubmit={handleChangePasswordSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label className="form-label">
                                  New Password
                                </label>
                                <div className="eye_pass">
                                  <input
                                    className="form-control"
                                    type={type}
                                    name="Password"
                                    id="Password"
                                    placeholder="Enter New Password"
                                    value={password}
                                    onChange={handleChange101}
                                  />
                                  <span
                                    className="password__show eye1"
                                    onClick={() => {
                                      setType(
                                        type == "text" ? "password" : "text"
                                      );
                                    }}
                                  >
                                    {type === "text" ? (
                                      <i className="las la-eye"></i>
                                    ) : (
                                      <i className="las la-low-vision"></i>
                                    )}
                                  </span>
                                  {passwordIssue && (
                                    <div style={{ color: "red" }}>
                                      {passwordIssue}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Confirm Password
                              </label>
                              <div className="form-group eye_pass">
                                <input
                                  className="form-control"
                                  type={type1}
                                  placeholder="Enter Confirm Password"
                                  //   onBlur={handleBlurChange}
                                  name="CPassword"
                                  onChange={handleChange101}
                                  value={cPassword}
                                />
                                <span
                                  className="password__show eye1"
                                  onClick={() => {
                                    setType1(
                                      type1 == "text" ? "password" : "text"
                                    );
                                  }}
                                >
                                  {type1 === "text" ? (
                                    <i className="las la-eye"></i>
                                  ) : (
                                    <i className="las la-low-vision"></i>
                                  )}
                                </span>

                                {cPasswordIssue && (
                                  <div style={{ color: "red" }}>
                                    {cPasswordIssue}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <button className="btn w100 btn_man">Submit</button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer limit={1} pauseOnHover={false} autoClose={4000} />
          </div>
        </div>
      </DocumentMeta>
    </>
  );
}

export default ForgotForm;
