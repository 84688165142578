import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import DocumentMeta from "react-document-meta";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,
      spotTab: "",
      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: "",
      showData: [],
      totalBalance: 0,
      defaultValue: "",
      button: "Buy",
      selectedCoin: "Select Crypto",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login-to-buy-sell-crypto";
    }

    // this.spotList();
    this.trasactionList();
    this.WalletAllCoinBalance();
  }

  componentDidMount() {
    // this.OpenMain("BTC");
  }
  trasactionList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/transaction-list1", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;

        if (resp.status) {
          this.setState({
            allData: resp.data,
            trasactionList: resp.data.main_wallet,
            cryptoIdWallet: resp.data.coin_id,
            TransactionsStatus: resp.status,
            defaultValue: resp.data[0].walletSum[0].walletAmount,
            defaultWalletTable: resp.data[0].wallet, //,spotList: resp.data.Spot_wallet,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });

    if (this.state.showData === 1) {
      //  this.setState({totalBalance : resp.data[0].getValue.main[0].coin_amount});
    }
  };

  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };

  showTableHtml = (value) => {
    if (
      this.state.TransactionsStatus === true &&
      Object.keys(this.state.showData).length > 0 &&
      this.state.selectedCoin
    ) {
      const html = [];

      let filteredTransactions=[]

      console.log(this.state.selectedCoin,"check dvsn")

      if(this.state.selectedCoin =="Select Crypto"){
       filteredTransactions =
        value &&
        value.wallet &&
        value.wallet
      }
      else{
        filteredTransactions = value &&
        value.wallet &&
        value.wallet.filter(
          (e) => e.firstCoinName === this.state.selectedCoin
        );
      }
      if (filteredTransactions && filteredTransactions.length > 0) {
        filteredTransactions.forEach((e, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>Buy</td>
              <td>{e.coin_pair}</td>
              <td> {e.per_price}</td>
              <td>{e.total_buy_get_amount}</td>
              <td>{e.buy_fees}</td>
              <td>{e.per_price * e.total_buy_get_amount + e.buy_fees}</td>
              <td>{Moment(e.created_at).format("lll")}</td>
            </tr>
          );
        });
        return <tbody className="">{html}</tbody>;
      } else {
        html.push(
          <tr>
            <td colSpan={8} className="text-center">
              No Data Found
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  renderCoinList = (allData) => {
    let { mainWallet } = this.state;
    return allData.map((item, index) => {
      let active = false;
      if (mainWallet == item.coin_id) {
        active = true;
      }
      return (
        <li onClick={() => this.OpenMain(item)}>
          <span className={active ? "active" : <></>} key={index}>
            {item.name}
          </span>
        </li>
      );
    });
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ selectedCoin: value });
  };

  render() {
    const meta = {
      title: `Buy | Trade History | ${ProductName}  Exchange`,
      description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { showData, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  <div className="white_box">
                    <div className="row align-items-center mb-2">
                      <h5 className="nav-item m-0 col-auto">Trade History</h5>
                      <div className="buyselll_box ml-auto col-auto">
                        <ul className="nav buy_sell_tab   ">
                          <li className="nav-item ">
                            <Link to="/spot" className="active">
                              {" "}
                              Buy{" "}
                            </Link>
                          </li>
                          <li className="nav-item ">
                            <Link to="/spotSellHistory"> Sell </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 col-lg-2 mt-md-0 mt-3">
                        <select
                          className="form-control"
                          value={this.state.selectedCoin}
                          onChange={this.handleChange}
                        >
                          <option>Select Crypto</option>
                          {allData.length > 0 &&
                            allData[0].coinList.map((item) => {
                              return (
                                <option value={item.short_name}>
                                  {item.short_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className=" top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Trade Type</th>
                                      <th>Pair </th>
                                      <th>Price</th>
                                      <th>Quantity</th>
                                      <th>Trade Fee</th>
                                      <th>Total</th>
                                      <th>Created</th>
                                    </tr>
                                  </thead>

                                  {this.showTableHtml(this.state.showData)}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane " id="Transactions">
                        <div className="tab-content">
                          <div className="tab-pane active " id="Ledger"></div>
                          <div className="tab-pane" id="Transactions2">
                            <div className="wallet_box">
                              <div className="table-responsive">
                                <table className="table ">
                                  <thead>
                                    <tr>
                                      <th>Created</th>
                                      <th>Deposit</th>
                                      <th>Withdraw</th>
                                      <th className="tr">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* {this.showTableHtml()} */}
                                    <tr>
                                      <td>Nov 20, 2020</td>
                                      <td>200</td>
                                      <td>Description </td>
                                      <td className="tr">0</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </DocumentMeta>
    );
  }
}

export default transactions;
