import React, { useEffect, useState } from "react";
import { EmailValidation } from "../Validation/EmailValidation";
import axios from "axios";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { MobileApi, checkKycStatus } from "./Apis";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";

const baseUrl = myConstList.baseUrl;
const Personal = (props) => {
  console.log("props", props);
  const [userdata, setUserData] = useState([]);
  const [length, setLength] = useState(0);
  const [email, setEmail] = useState("");
  const [verification_code, setVerification_code] = useState("");
  const [counter, setCounter] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeVerifyStatus, setCodeVerifyStatus] = useState(false);
  const [codeBtnDisable, setCodeBtnDisable] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [countryCode, setCountryCode] = useState("+");
  const [disableMobile, setDisableMobile] = useState(false);

  const user = props.userinfo && props.userinfo[0];

  const isKycComplete =
    user?.selfie_status === "1" && user?.passport_status === "1";

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setLength(props.userinfo.length);
      setUserData(props.userinfo);

      if (props.userinfo[0].pi_status == "2") {
        setCodeBtnDisable(true);
      }
      setEmail(props.userinfo[0].email);
      setCountryCode(
        props.userinfo[0].country_code ? props.userinfo[0].country_code : "+"
      );
      setMobile(props.userinfo[0].mobile_no);
    }
  }, [props]);
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name == "email") {
      value = value.toLowerCase();
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
        setVerification_code("");
        // this.setState({textForCode:"Get Code"})
        // this.setState({VerificationCode:""})
      }
    }
    if (name == "verification_code") {

      const onlyNumbersRegex = /^[0-9]*$/;
      // Check if the input has exactly 10 digits
      const tenDigitsRegex = /^\d{6}$/;
      
      if (!onlyNumbersRegex.test(value)) {
        // setMobileError("Only numbers are allowed");
        return;
      }

  
        if(value.length<7){
          setVerification_code(value);  
        }

        console.log(value)
  
 
      
      if (!tenDigitsRegex.test(value) && value.length<7 && value.length !==0) {
        setCodeError("Please Enter a valid otp");
        return;
      }
      else{
        setCodeError("");
      }
      


      if (!value) {
        setCodeError("This field is required");
        return;
      }













    }
    if (name == "mobile") {
      setMobile(value);
      if (value === "") {
        setMobileError("This field is required");
        return false;
      }
      if (!(value.length >= 5 && value.length <= 15)) {
        setMobileError("Invalid mobile number");
        return false;
      }
      // const error = MobileValidation(name, value)
      setMobileError("");
    }
  };
  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setCodeBtnDisable(true);
      setTimerText("Resend In");
      setDisableMobile(true);
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setCodeBtnDisable(false);
        setTimerText("");
        setDisableMobile(false);
      }
    }, 1000);
  };
  const getCode = async () => {
    if (emailError) {
      return false;
    }
    const emailvalidation = EmailValidation("email", email);
    if (emailvalidation) {
      setEmailError(emailvalidation);
      return false;
    }

    let data = {
      email: email,
      page: "kyc",
    };
    await axios.post(baseUrl + "/frontapi/getcode", data).then((resp) => {
      let response = resp.data;
      if (response.status) {
        // setVerification_code(response.code)
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  };

  const getCodeMobile = async () => {
    if (mobileError) {
      return false;
    }

    const regex = /^\+\d{1,4}$/;

    if (!regex.test(countryCode)) {
      // setCountryCode("+");
      setMobileError("Please enter a valid country code");
      return;
    }

    if (!mobile) {
      setMobileError("This field is required");
      return;
    }

    if (mobile.length < 5 || mobile.length > 15) {
      setMobileError(
        "Mobile number should be atleast 5 and not more than 15 digits"
      );
      return;
    }

    let data = {
      mobile: mobile,
      page: "kyc",
      selectedFiledType: selectedFiledType,
      countryCode: countryCode,
    };
    await axios.post(baseUrl + "/frontapi/getcode", data).then((resp) => {
      let response = resp.data;
      if (response.status) {
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  };

  const handleSubmit = async (e) => {
    if (!email && !verification_code) {
      setEmailError("This field is required");
      setCodeError("This field is required");
      return false;
    }
    if (!email) {
      setEmailError("This field is required");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }
    if (emailError || codeError) {
      return false;
    }
    let data = {
      page: "kyc",
      email: email,
      mobile: mobile,
      verification_code: verification_code,
      selectedFiledType: userdata[0].mobile_no ? "email" : "mobile",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    await axios
      .post(baseUrl + "/frontapi/codeVerify", data, config)
      .then((resp) => {
        setBtnDisable(false);
        let response = resp.data;
        if (response.status) {
          toast.success(response.message);
          props.resendResult(true);
          setCodeVerifyStatus(true);
        } else {
          toast.error(response.message);
        }
      });
  };
  const handleMobileSubmit = async () => {
    if (!mobile) {
      setMobileError("This field is required");
      return false;
    }
    if (!(mobile.length >= 7 && mobile.length <= 15)) {
      setMobileError("Invalid mobile number");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }

    if (mobileError) {
      return false;
    }
    let data = {
      mobile: mobile,
      code: verification_code,
      countryCode: countryCode,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await MobileApi(data, config);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
    } else {
      toast.error(result.message);
    }
  };

  const handleOnChange = (value, country) => {
    // You can access the selected country and formatted phone number here

    if (!value) {
      setSelectedFiledType("email");
    } else {
      setSelectedFiledType("mobile");
    }

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    setMobile(adjustedMobile);
    setTextForCode("Get Code");
    setCountryCode("+" + country.dialCode);
    setMobileError("");
    setEmailError("");
  };

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;

    // setEmailError("");
    // setEmail("");
    // setSelectedFiledType("mobile");
    if (name === "phone") {


    const onlyNumbersRegex = /^[0-9]*$/;
      // Check if the input has exactly 10 digits
      const tenDigitsRegex = /^\d{10}$/;

      console.log(value,"dfasdf")
      
      if (!onlyNumbersRegex.test(value)) {
        console.log(value,"dfas55df")
      
        // setMobileError("Only numbers are allowed");
        console.log("99999999999")
        if(value.length==1){
          setMobile("");
        }
        return;
      }


      if(value[0]>=6 ){
        console.log("dsfsadfadsf")
        if(value.length<11){
          setMobile(value);  
        }
      }
      else{
        setMobile(""); 
        setMobileError("This field is required");
        return; 
      }

 
      
      if (!tenDigitsRegex.test(value) && value.length<11 && value.length !==0) {
        setMobileError("Please Enter a valid Mobile Number");
        return;
      }
      


      if (!value) {
        setMobileError("This field is required");
        return;
      }
      // setSelectedFiledType("mobile"); // Otherwise, stay on mobile input

      setEmailError("");
      setMobileError("");

      // if (!value) {
      //   setSelectedFiledType("email");
      // } else {
      //   setSelectedFiledType("mobile");
      // }

      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }

      // setMobile(value);
      // setCountryCode("+" + country.dialCode);
      // setMobileError("");
    }

    // if (name === "countryCode") {
    //   if (!value || value.length < 2) {
    //     setCountryCode("+");
    //     setMobileError("Country code is required");
    //     return;
    //   }

    //   // setCountryCode(value);

    //   const regex = /^\+\d{1,4}$/;

    //   if (!regex.test(value)) {
    //     // setCountryCode("+");
    //     setMobileError("Please enter a valid country code");
    //     return;
    //   }

    //   setMobileError("");
    // }
  };

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let result = await checkKycStatus({}, config);
    if (result.status) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  return (
    <>
      <div className="personal-outer">
        <div className="row align-items-top">
          <div className="col-md-3 col-12">
            <div className="white_box heading-main ">
              <h6
                className={
                  props.status.length > 0 && props.status[0].personal == "2"
                    ? "position-relative complete"
                    : "position-relative "
                }
              >
                Verify Contact
              </h6>

              <h6
                className={
                  props.status.length > 0 && props.status[0].passport == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                Identity Proof
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].pan == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                Pancard
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].selfie == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                Face Verification
              </h6>
              {/* {isKycComplete && (
                <button
                  className="btn btn_man mt-2"
                  onClick={handleCheckStatus}
                >
                  Check KYC Status
                </button>
              )} */}
            </div>
          </div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              <h6 className="position-relative">
                {length > 0 && userdata[0].mobile_no && "Email Address"}
              </h6>
              <p>
                Fill in your personal information the way it is stated in the
                proof of identity document you submit
              </p>
              <div className="fs12">
                {length > 0 && (
                  <form>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter Email Address"
                          minLength="2"
                          maxlength="100"
                          value={email}
                          maxLength="64"
                          disabled={
                            length > 0 && userdata[0].email
                              ? true
                              : false || codeBtnDisable
                          }
                          onChange={handleChange}
                        />
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                        {length > 0 && !userdata[0].email && (
                          <div className="submit-btn">
                            <button
                              className="btn btn_man fs12 mt-3"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                      {length > 0 && !userdata[0].email && (
                        <div className="form-group col-md-6">
                          <label>Verification Code</label>
                          <div className="input-group-prepend">
                            <input
                              type="text"
                              className="form-control"
                              name="verification_code"
                              placeholder="Enter Verification Code"
                              minLength="2"
                              maxlength="20"
                              value={verification_code}
                              disabled={!codeBtnDisable}
                              onChange={handleChange}
                            />
                            <div className="input-group-prepend">
                              <button
                                className="btn btn_man"
                                id="btnGroupAddon"
                                type="button"
                                onClick={getCode}
                                disabled={codeBtnDisable}
                              >
                                {timerText}
                                {"  "}
                                {counter == 0 ? textForCode : counter}
                              </button>
                            </div>
                          </div>
                          {codeError && (
                            <div className="text-danger">{codeError}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                )}

                {length > 0 && (
                  <div className="row  ">
                    <div className="form-group kyc-right col-md-6 m-0">
                      <label>Mobile Number</label>
                      {!userdata[0].mobile_no ? (
                        <div className="row">
                          <div className="form-group col-3">
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              placeholder="Country Code"
                              name="countryCode"
                              autoFocus
                              value={countryCode}
                              disabled={codeBtnDisable}
                              onChange={handleChangeMobile}
                              readOnly
                              // disabled={counter == 0 ? false : true}
                            />
                          </div>
                          <div className="form-group col-9 pl-0">
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              placeholder="Enter Mobile No."
                              name="phone"
                              autoFocus
                              value={mobile}
                              // disabled={codeBtnDisable}
                              onChange={handleChangeMobile}
                              disabled={
                                codeBtnDisable
                                  ? codeBtnDisable
                                  : counter == 0
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="form-group col-3">
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              placeholder="Country Code"
                              name="countryCode"
                              autoFocus
                              value={countryCode}
                              disabled={true}
                              onChange={handleChangeMobile}
                            />
                          </div>
                          <div className="form-group col-9 pl-0">
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              placeholder="Enter Mobile No."
                              name="phone"
                              autoFocus
                              value={mobile}
                              onChange={handleChangeMobile}
                              disabled={true}
                            />
                          </div>
                        </div>
                      )}
                      {mobileError && (
                        <div className="text-danger">{mobileError}</div>
                      )}
                    </div>
                    {length > 0 && !userdata[0].mobile_no && (
                      <div className="form-group col-md-6 m-0">
                        <label>Verification Code</label>
                        <div className="input-group-prepend">
                          <input
                            type="text"
                            className="form-control"
                            name="verification_code"
                            placeholder="Enter Verification Code"
                            minLength="2"
                            maxlength="20"
                            value={verification_code}
                            onChange={handleChange}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn_man"
                              id="btnGroupAddon"
                              type="button"
                              onClick={getCodeMobile}
                              disabled={codeBtnDisable}
                            >
                              {timerText}
                              {"  "}
                              {counter == 0 ? textForCode : counter}
                            </button>
                          </div>
                        </div>
                        {codeError && (
                          <div className="text-danger">{codeError}</div>
                        )}
                      </div>
                    )}
                    {length > 0 && !userdata[0].mobile_no && (
                      <div className="col-md-6 mb-md-1 mt-1">
                        <button
                          className="btn btn_man fs12"
                          type="button"
                          onClick={handleMobileSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Personal;
