import React, { useEffect, useRef, useState } from "react";
import ExchangeKycHeader from "../Common/ExchangeHeader";
import TradingViewChart from "./TradingView";
import { Link, useParams } from "react-router-dom";
import Ticker from "./Ticker";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, socketUrl } from "../Common/BaseUrl";
import Moment from "moment";
import $ from "jquery";
import axios from "axios";
import MarketTrade from "./MarketTrade";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";
import socketIOClient from "socket.io-client";
import DocumentMeta from "react-document-meta";
import BigNumber from "bignumber.js";
import { formatDecimal } from "../../utils/decimalFormatter";

const ExchnageFuncSocket = (props) => {
  const clientRef = useRef(null);
  const binanceWebsocket = useRef(null);
  const params = useParams();
  const [firstCoin, setFirstCoin] = useState("");
  const [isActive, setIsActive] = useState("N");
  const [secondCoin, setSecondCoin] = useState("");
  const [setUrl, setSetUrl] = useState("");
  const [exchange_tab, setExchange_tab] = useState([]);
  const [coinListData, setCoinListData] = useState([]);
  const [exchange, setExchange] = useState("");
  const [topBarStatus, setTopBarStatus] = useState(false);
  const [topBarApiData, setTopBarApiData] = useState([]);
  const [buyAmount, setBuyAmount] = useState("");
  const [setActiveTab, setSetActiveTab] = useState(params.secondCoin);
  const [buy_order_total, setBuy_order_total] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [coinListStatus, setCoinListStatus] = useState(false);
  const [mySpotBalance, setMySpotBalance] = useState([]);
  const [firstCoinNew, setFirstCoinNew] = useState("");
  const [secondCoinBalance, setSecondCoinBalance] = useState(0);
  const [firstCoinBalance, setFirstCoinBalance] = useState(0);
  const [limit, setLimit] = useState(true);
  const [orderMethod, setOrderMethod] = useState("limit");
  const [market, setMarket] = useState(false);
  const [stopLimit, setStopLimit] = useState(false);
  const [stopPriceBuy, setStopPriceBuy] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [currentPrice, setCurrentPrice] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [type, setType] = useState("number");
  const [order_id, setOrder_id] = useState("");
  const [buy_commission, setBuy_commission] = useState("");
  const [bPBtn, setBPBtn] = useState(0);
  const [feeAmyt, setFeeAmyt] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sell_order_total, setSell_order_total] = useState("");
  const [sellFeeAmyt, setSellFeeAmyt] = useState("");
  const [disabledBuuButtn, setDisabledBuuButtn] = useState(false);
  const [isOrderEdit, setIsOrderEdit] = useState(false);
  const [isFeeEnable, setIsFeeEnable] = useState("0");
  const [isFeeApply, setIsFeeApply] = useState("N");
  const [sell_commission, setSell_commission] = useState("");
  const [firstCoinId, setFirstCoinId] = useState("");
  const [secondCoinId, setSecondCoinId] = useState("");
  const [buy_order_total_write, setBuy_order_total_write] = useState("");
  const [stopPriceSell, setStopPriceSell] = useState("");
  const [sell_order_id, setSell_order_id] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [sPBtn, setSPBtn] = useState(0);
  const [disabledSellButtn, setDisabledSellButtn] = useState(false);
  const [activeSellOrder, setActiveSellOrder] = useState(false);
  const [activeSellOrderData, setActiveSellOrderData] = useState([]);
  const [activeBuyOrder, setActiveBuyOrder] = useState(false);
  const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
  const [marketTrade, setMarketTrade] = useState(false);
  const [marketTradeData, setMarketTradeData] = useState([]);
  const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
  const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
  const [updateAndDeleteButton, setUpdateAndDeleteButton] = useState(false);
  const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
  const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
  const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
  const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
  const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
  const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
  const [InExtype, setInExtype] = useState("exclude");
  const [refresh, setRefresh] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isEditModelOpenSell, setIsEditModelOpenSell] = useState(false);
  const [themeRefresh, setThemeRefresh] = useState(false);
  const [fav_first_coin, setfav_first_coin] = useState("");
  const [fav_second_coin, setfav_second_coin] = useState("");
  const [buyPercentage, setBuyPercentage] = useState("");
  const [portfolioData, setPortfolioData] = useState("");
  const [livPrice, setLivPrice] = useState("0:00");
  const [onPriceChange, setOnPriceChange] = useState(true);
  const [onChangeChange, setOnChangeChange] = useState(true);
  const [binanceTicker, setBinanceTicker] = useState([]);
  const [binanceTrades, setBinanceTrades] = useState([]);
  const [binanceDepth, setBinanceDepth] = useState([]);

  const sortCoin = (type) => {
    if (type === "price") {
      setOnPriceChange(!onPriceChange);
    }
    if (type === "change") {
      setOnChangeChange(!onChangeChange);
    }
  };

  const token = localStorage.getItem("token");
  const streams = ["@ticker", "@depth20", "@trade"];

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const closeEditModel = () => {
    setIsEditModelOpen(false);
    setIsEditModelOpenSell(false);
    setTimeout(() => {
      setDisabledBuuButtn(false);
      setDisabledSellButtn(false);
    }, 1500);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/cancle-back", {}, config).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
      }
    });
  };

  const onEditOrder = (e) => {
    if (secondCoinBalance === 0 || secondCoinBalance === null) {
      setInExtype("include");
    }

    const { name, value } = e.target;

    if (name === "editPrice" || name === "editAmount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      // Regular expression for validating the input
      const regex = /^[0-9]{1,8}(\.[0-9]{1,8})?$/;

      if (regex.test(newValue)) {
        // Value is a valid number
        if (value.length < 17) {
          // Check total length (including digits and decimal point)
          if (name === "editPrice") {
            setBuyPrice(newValue);
          } else if (name === "editAmount") {
            setBuyAmount(newValue);
          }
        }
      } else {
        if (name === "editPrice") {
          setBuyPrice("");
        } else if (name === "editAmount") {
          setBuyAmount("");
        }
      }
    }

    setInExtype("exclude");
  };

  const onEditOrderSell = (e) => {
    if (firstCoinBalance === 0 || firstCoinBalance === null) {
      setInExtype("include");
    }

    const { name, value } = e.target;

    if (name === "editPrice" || name === "editAmount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      // Regular expression for validating the input
      const regex = /^[0-9]{1,8}(\.[0-9]{1,8})?$/;

      if (regex.test(newValue)) {
        // Value is a valid number
        if (newValue.length < 17) {
          // Check total length (including digits and decimal point)
          if (name === "editPrice") {
            setSellPrice(newValue);
          } else if (name === "editAmount") {
            setSellAmount(newValue);
          }
        }
      } else {
        if (name === "editPrice") {
          setSellPrice("");
        } else if (name === "editAmount") {
          setSellAmount("");
        }
      }
    }
  };

  useEffect(() => {
    axios
      .get(baseUrl + "/api/crypto-data-by-tab")
      .then((res) => {
        setExchange_tab(res.data);
      })
      .catch();

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/generate-pay-id", {}, config);
  }, []);

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      axios
        .get(baseUrl + "/api/get-open-pair")
        .then((res) => {
          if (res.data.status) {
            const pairName = `/${res.data.data[0].first_name}/${res.data.data[0].second_name}`;
            window.location.href = `/exchange${pairName}`;
          }
        })
        .catch((err) => {});
    }
    startDataLoad();
    favourite();
  }, [params.firstCoin, params.secondCoin]);

  let favourite = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        var apiResp = respData.data;
        var apiRespData = apiResp.data;
        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp.success === true) {
                // setExchange(apiExchangeResp.data.exchange);
                localStorage.setItem("exType", apiExchangeResp.data.exchange);
              } else {
                localStorage.setItem("exType", "notExchange");
                // setExchange("");
              }
            });
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

          axios
            .post(baseUrl + "/frontapi/check-favcoin", sendCoinPair, config)
            .then((respData) => {
              var resp = respData.data;
              setIsActive(resp.data);
            });
        }
      });
  };

  const _connectSocketStreams = (streams) => {
    let newArr = [];
    // Close the previous WebSocket connection
    if (binanceWebsocket.current) {
      binanceWebsocket.current.close();
    }
    try {
      streams = streams.join("/");
      // Create a new WebSocket connection
      binanceWebsocket.current = new WebSocket(
        `wss://stream.binance.com:9443/stream?streams=${streams}`
      );
      binanceWebsocket.current.onmessage = async (evt) => {
        const eventData = JSON.parse(evt.data);
        if (eventData.stream.endsWith("@ticker")) {
          const obj = eventData.data;
          setLivPrice(obj.c);
          setBinanceTicker(obj);
          setTopBarStatus(true);
        }

        if (eventData.stream.endsWith("@trade")) {
          const mainValue = eventData.data;
          const data = await secondConstructor(mainValue);
          newArr.push(data);
          if (newArr.length > 10) {
            newArr.shift();
          }
          const binanceTrades = { binanceTrades: newArr };
          const trades = binanceTrades.binanceTrades;
          setBinanceTrades(trades);
        }

        if (eventData.stream.endsWith("@depth20")) {
          setBinanceDepth(eventData.data);
        }
      };

      binanceWebsocket.current.onerror = (evt) => {
        console.error(evt);
      };
    } catch (error) {
      console.log("err2", error);
    }
  };

  const secondConstructor = async (obj) => {
    return {
      cost: obj.p,
      amount: obj.q,
      timestamp: obj.T,
    };
  };

  useEffect(() => {
    const firstCoinName = params.firstCoin;
    const secondCoinName = params.secondCoin;
    const pair = firstCoinName + secondCoinName;
    const symbol = pair.toLowerCase();
    if (exchange === "binance") {
      _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
    }

    const pairData = { firstCoin: firstCoinName, secondCoin: secondCoinName };
    if (!clientRef.current) {
      clientRef.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("token"),
        },
      });
      clientRef.current.on("connect", () => {
        clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
        clientRef.current.emit("orderBookData", pairData); // Emit an event to request order book data for the pair
        clientRef.current.emit("topBarData", pairData); // Emit an event to request top bar data for the pair
        clientRef.current.emit("coinPairList"); // Emit an event to request coin pair data for the pair
        clientRef.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
        handleSocketData(
          clientRef.current,
          clientRef.current.id,
          params.firstCoin,
          params.secondCoin
        );
      });
    }

    const token = localStorage.getItem("token");
    if (token) {
      subscribeToUserStreams(
        clientRef.current,
        params.firstCoin,
        params.secondCoin
      );
    }
    return () => {
      if (
        clientRef.current &&
        !window.location.pathname.includes("/exchange")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.disconnect();
      }
    };
  }, [params, exchange]);

  const handleSocketData = (socket, id, firstCoin, secondCoin) => {
    socket.off(`marketDataPairApi_${firstCoin}_${secondCoin}`);
    socket.on(`marketDataPairApi_${firstCoin}_${secondCoin}`, (socketData) => {
      const { buyOrderData, sellOrderData } = socketData;
      const activeBuyOrderData = buyOrderData;
      const activeSellOrderData = sellOrderData;
      if (activeBuyOrderData.status) {
        setActiveBuyOrder(activeBuyOrderData.status);
        setActiveBuyOrderData(activeBuyOrderData.data);
      } else {
        setActiveBuyOrder(activeBuyOrderData.status);
        setActiveBuyOrderData([]);
      }
      if (activeSellOrderData.status) {
        setActiveSellOrder(activeSellOrderData.status);
        setActiveSellOrderData(activeSellOrderData.data);
      } else {
        setActiveSellOrder(activeSellOrderData.status);
        setActiveSellOrderData([]);
      }
    });

    socket.off(`orderBookDataApi_${firstCoin}_${secondCoin}`);
    socket.on(`orderBookDataApi_${firstCoin}_${secondCoin}`, (orderData) => {
      const { data, status, lastPrice } = orderData;
      setCurrentPrice(lastPrice);
      setMarketTrade(status);
      setMarketTradeData(data);
    });

    socket.off(`topBarDataApi_${firstCoin}_${secondCoin}`);
    socket.on(`topBarDataApi_${firstCoin}_${secondCoin}`, (topBarData) => {
      const { data, status } = topBarData;
      setTopBarApiData(data);
      setTopBarStatus(status);
    });

    socket.off("coinPairListApi");
    socket.on("coinPairListApi", (coinListData) => {
      const { data, status } = coinListData;
      setCoinListData(data);
      setCoinListStatus(status);
    });
    socket.off(`resultPairBinance_${firstCoin}_${secondCoin}`);
    socket.on(`resultPairBinance_${firstCoin}_${secondCoin}`, (topBarData) => {
      const { data, status } = topBarData;
      if (status === true) {
        setExchange(data.exchange);
      } else {
        setExchange("");
      }
    });
    socket.off("refresh");
    socket.on("refresh", () => {
      const pairData = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };

      clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("orderBookData", pairData); // Emit an event to request order book data for the pair
      clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
      handleSocketData(
        clientRef.current,
        clientRef.current.id,
        firstCoin,
        secondCoin
      );
      setRefresh(!refresh);

      const token = localStorage.getItem("token");
      if (token) {
        subscribeToUserStreams(clientRef.current, firstCoin, secondCoin);
      }
    });
  };

  const subscribeToUserStreams = (socket, firstCoin, secondCoin) => {
    const pairData = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
    };
    socket.emit("userPendingOrders", pairData);
    socket.emit("userCompleteOrders", pairData);
    socket.emit("userBalanceByPair", pairData);
    socket.emit("mySpotBalance", pairData);
    socket.emit("userRealTimePortfolio", pairData);

    handleUserSocketResp(socket);
  };

  const handleUserSocketResp = (socket) => {
    socket.off("userPendingOrdersApi");
    socket.on("userPendingOrdersApi", (pendingOrders) => {
      const { buyOrderData, sellOrderData } = pendingOrders;

      if (buyOrderData.status) {
        setMyActiveBuyOrder(buyOrderData.status);
        setMyActiveBuyOrderData(buyOrderData.data);
      } else {
        setMyActiveBuyOrder(buyOrderData.status);
        setMyActiveBuyOrderData([]);
      }
      if (sellOrderData.status) {
        setMyActiveSellOrder(sellOrderData.status);
        setMyActiveSellOrderData(sellOrderData.data);
      } else {
        setMyActiveSellOrder(sellOrderData.status);
        setMyActiveSellOrderData([]);
      }
    });

    socket.off("userCompleteOrdersApi");
    socket.on("userCompleteOrdersApi", (completedOrders) => {
      const { buyOrderData, sellOrderData } = completedOrders;

      if (buyOrderData.status) {
        setMyCompletedBuyOrder(buyOrderData.status);
        setMyCompletedBuyOrderData(buyOrderData.data);
      } else {
        setMyCompletedBuyOrder(buyOrderData.status);
        setMyCompletedBuyOrderData([]);
      }
      if (sellOrderData.status) {
        setMyCompletedSellOrder(sellOrderData.status);
        setMyCompletedSellOrderData(sellOrderData.data);
      } else {
        setMyCompletedSellOrder(sellOrderData.status);
        setMyCompletedSellOrderData([]);
      }
    });

    socket.off("userBalanceByPairApi");
    socket.on("userBalanceByPairApi", (userBalanceByPairApi) => {
      const { firstCoinBalance, secondCoinBalance } = userBalanceByPairApi;
      setFirstCoinBalance(firstCoinBalance);
      setSecondCoinBalance(secondCoinBalance);
    });

    socket.off("mySpotBalanceApi");
    socket.on("mySpotBalanceApi", (mySpotBalanceApi) => {
      setMySpotBalance(mySpotBalanceApi);
    });

    socket.off("userRealTimePortfolioApi");
    socket.on("userRealTimePortfolioApi", (portfolioBalc) => {
      setPortfolioData(portfolioBalc);
    });
  };

  const startDataLoad = () => {
    setFirstCoin(params.firstCoin);
    setSecondCoin(params.secondCoin);
    setSetUrl(params.secondCoin);
    setSecondCoinBalance(0);
    getPairDetails();
  };

  const getPairDetails = () => {
    let sendData = {
      firstCoin: params.firstCoin,
      secondCoin: params.secondCoin,
    };
    axios.post(baseUrl + "/frontapi/checkpair", sendData).then((respData) => {
      const apiResp = respData.data;

      console.log(apiResp,"dvsn check")
      if (apiResp.success === false) {
      }
      const apiRespData = apiResp.data;
      setFirstCoinId(apiRespData.firstCoinId);
      setSecondCoinId(apiRespData.secondCoinId);
      setBuyPrice(formatDecimal(apiRespData.currentPairPrice));
      setCurrentPrice(apiRespData.currentPairPrice);
      setSellPrice(formatDecimal(apiRespData.currentPairPrice));
      setBuy_commission(apiRespData.commission);
      setSell_commission(apiRespData.sell_commission);
      setfav_first_coin(apiRespData.firstCoinId);
      setfav_second_coin(apiRespData.secondCoinId);
      getUserDetails();
    });
  };

  const getUserDetails = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      const resp = res.data;
      if (
        resp.key === "logout" &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null
      ) {
        localStorage.clear();
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      }
    });
  };

  const dataOnClick = (firstCoin, secondCoin, isBinance) => {
    if (binanceWebsocket.current) {
      binanceWebsocket.current.close();
    }
    if (isBinance === true) {
      let data = firstCoin + secondCoin;
      let symbol = data.toLowerCase();
      _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
      setExchange("binance");
    } else {
      setExchange("");
    }

    if (params.firstCoin !== firstCoin) {
      const pairData = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };
      clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair

      handleSocketData(
        clientRef.current,
        clientRef.current.id,
        firstCoin,
        secondCoin
      );
      setRefresh(!refresh);
    }
    localStorage.setItem("tab", setActiveTab);
  };

  useEffect(() => {
    const activeTab = localStorage.getItem("tab");
    if (activeTab) {
      setSetActiveTab(activeTab);
      activeCurrency(activeTab);
    }
  }, []);

  const changeTheme = () => {
    setThemeRefresh(!themeRefresh);
  };

  const favListApi = (v) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let sendData = {
      firstCoin: fav_first_coin,
      secondCoin: fav_second_coin,
    };
    axios
      .post(baseUrl + "/frontapi/addFavCoin", sendData, config)
      .then((respData) => {
        const apiResp = respData.data;
        linkPairClick();
        if (apiResp.status === true) {
          toast.success(apiResp.message);
          getPairDetails();
          if (setActiveTab === "fav") {
            activeCurrency("fav");
          }
        } else {
          toast.error(apiResp.message);
          getPairDetails();
        }
      });
  };
  const linkPairClick = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        const apiResp = respData.data;
        const apiRespData = apiResp.data;

        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              const apiExchangeResp = respData.data;
            });
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

          axios
            .post(baseUrl + "/frontapi/check-favcoin", sendCoinPair, config)
            .then((respData) => {
              var resp = respData.data;
              setIsActive(resp.data);
            });
        }
      });
  };

  const topBarHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        return (
          <ul>
            <Ticker {...binanceTicker} />
          </ul>
        );
      } else {
        const topBarData = topBarApiData;

        var collectHtml = (
          <ul>
            <li>
              <h4 className="ng-binding">Last Price</h4>
              <strong className="ng-binding">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice > 1
                  ? topBarData.currentPrice.toLocaleString()
                  : topBarData.currentPrice}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
            <li>
              <h4 className="ng-binding">24h-Change</h4>
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
              topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope green">
                    {"0.00"}
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePriceAmount == null ? (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePriceAmount}
                    </strong>
                  ) : (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePriceAmount.toFixed(2)}
                    </strong>
                  )}
                </>
              )}
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
              topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope green">
                    {" "}
                    {0}%
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePricePercent == null ||
                  topBarData.changePricePercent.toFixed(2) == null ? (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePricePercent}%
                    </strong>
                  ) : (
                    <strong>
                      {" "}
                      ({topBarData.changePricePercent.toFixed(2)}%)
                    </strong>
                  )}
                </>
              )}
            </li>
            <li>
              <h4 className="ng-binding">24h-High</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMaxPrice == null
                  ? topBarData.currentPrice > 1
                    ? topBarData.currentPrice.toLocaleString()
                    : topBarData.currentPrice
                  : topBarData.oneDayMaxPrice}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h-Low</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMinPrice == null
                  ? topBarData.currentPrice > 1
                    ? topBarData.currentPrice.toLocaleString()
                    : topBarData.currentPrice
                  : topBarData.oneDayMinPrice}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h Volume</h4>
              <strong className="ng-binding">
                {topBarData.oneDayVolume == null
                  ? "0.00"
                  : topBarData.oneDayVolume}
              </strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <h4 className="ng-binding">Last Price</h4>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Change</h4>
            <strong className="changeRate ng-binding ng-scope green">
              0.00
            </strong>{" "}
            <strong className="changeRate ng-binding ng-scope green">
              (0.00)%
            </strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-High</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Low</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h Volume</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const activeCurrency = (value) => {
    localStorage.setItem("setUrl", value);
    setSetUrl(value);
    setBuyAmount("");
    setSellAmount("");
    setSell_order_total(0);
    setBuy_order_total(0);
    setSetActiveTab(value);
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const coinListHtml = (coinListData) => {
    var listData = [];
    if (coinListStatus === true && coinListData.length > 0) {
      listData = coinListData;
    }
    var filteredItems = listData.filter((item) =>
      item.first_coin_name.includes(searchedValue.toUpperCase())
    );
    filteredItems.sort((a, b) => {
      const calculatePercentShow = (item) => {
        const currentPrice = parseFloat(item.current_price);
        const oneDayBeforePrice = parseFloat(item.oneday_before_price);

        // Check if oneDayBeforePrice is a valid number
        if (isNaN(oneDayBeforePrice) || oneDayBeforePrice === null) {
          // Handle the case where oneDayBeforePrice is not a valid number
          return 0; // You can adjust this based on your specific requirements
        }

        const percentChange =
          ((currentPrice - oneDayBeforePrice) * 100) / oneDayBeforePrice;
        return parseFloat(percentChange.toFixed(2)); // Round to 2 decimal places
      };

      const percentShowA = calculatePercentShow(a);
      const percentShowB = calculatePercentShow(b);

      // Sorting based on descending order of percentShow
      return onChangeChange
        ? percentShowB - percentShowA
        : percentShowA - percentShowB;
    });

    return filteredItems.map((item, index) => {
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);
      var showActive =
        firstCoin === item.first_coin_name &&
        secondCoin === item.second_coin_name;

      if (setActiveTab == "fav" && item.activeStatus == "YES") {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <Link
              to={
                "/exchange/" +
                item.first_coin_name +
                "/" +
                item.second_coin_name
              }
              onClick={() =>
                dataOnClick(
                  item.first_coin_name,
                  item.second_coin_name,
                  item.isBinance
                )
              }
            >
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {formatDecimal(item.current_price)}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0 ? { color: "red" } : { color: "green" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </Link>
          </tr>
        );
      }
      if (item.second_coin_name == setActiveTab) {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <Link
              to={
                "/exchange/" +
                item.first_coin_name +
                "/" +
                item.second_coin_name
              }
              onClick={() =>
                dataOnClick(
                  item.first_coin_name,
                  item.second_coin_name,
                  item.isBinance
                )
              }
            >
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {isNaN(item.current_price) || item.current_price == null
                  ? "0"
                  : formatDecimal(item.current_price)}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0
                      ? { color: "#ff505d" }
                      : { color: "#00bc8b" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </Link>
          </tr>
        );
      }
    });
  };

  const showSpotBalance = () => {
    if (mySpotBalance.length > 0) {
      const html = [];
      mySpotBalance.length > 0 &&
        mySpotBalance.map(function (value, i) {
          if (
            value.spot[0].coin_amount === null ||
            value.spot[0].coin_amount === "null"
          ) {
            var spotBalace = 0;
          } else {
            spotBalace = value.spot[0].coin_amount;
          }
          if (spotBalace > 0) {
            html.push(
              <tr key={i}>
                <td>{value.name}</td>
                <td>{spotBalace}</td>
              </tr>
            );
          }
        });
      return <tbody className="main">{html}</tbody>;
    }
  };

  const openTradingChart = (symbol) => {
    let theme;
    if (localStorage.getItem("theme") === "true") {
      theme = Themes.DARK;
    } else {
      theme = Themes.LIGHT;
    }
    return (
      <TradingViewWidget
        symbol={"BINANCE:" + symbol}
        theme={theme}
        locale="en"
        autosize={true}
        details={false}
        BarStyles={BarStyles.HEIKIN_ASHI}
      />
    );
  };

  const removeTrailingZeros = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr).toFixed(8);

    return result;
  };
  const removeTrailingZerosT = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr);

    return result;
  };

  const setStopChanges = (e, data) => {
    e.preventDefault();
    if (data === "stop") {
      setStopLimit(true);
      setDisabledButton(false);
      setMarket(false);
      setLimit(false);
      setBuyAmount("");
      setBuy_order_total("");
      setFeeAmyt("");
      setSellAmount("");
      setSell_order_total("");
      setSellFeeAmyt("");
    }
    if (data === "market") {
      setBuyPrice(currentPrice);
      setSellPrice(currentPrice);
      if (isOrderEdit === true) {
        if (buyAmount > 0) {
          if (
            buy_commission !== null &&
            isFeeApply !== "Y" &&
            isFeeEnable !== "1"
          ) {
            var commission = (buyAmount * buy_commission) / 100;
            var buy_value = parseFloat(buyAmount) + parseFloat(commission);
          } else {
            buy_value = buyAmount;
          }
          const buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(currentPrice);
          var price = currentPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(currentPrice);
          var sellPrice = currentPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(true);
      setStopLimit(false);
      setMarket(true);
      setLimit(false);
    }
    if (data === "limit") {
      if (isOrderEdit === true) {
        if (
          buy_commission !== null &&
          isFeeApply !== "Y" &&
          isFeeEnable !== "1"
        ) {
          commission = (buyAmount * buy_commission) / 100;
          buy_value = parseFloat(buyAmount) + parseFloat(commission);
        } else {
          buy_value = buyAmount;
        }
        if (buyAmount > 0) {
          let buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(buyPrice);
          price = buyPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(sellPrice);
          sellPrice = sellPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(false);
      setStopLimit(false);
      setLimit(true);
      setMarket(false);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleBuySubmit = (event) => {
    event.preventDefault();

    if (buyPrice < 0) {
      toast.error("Please enter valid amount");
      return false;
    }
    if (buyAmount < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (buyPrice <= 0) {
      toast.error("Please enter valid price");
      return false;
    }

    var price = "";
    if (limit === true && market === false) {
      price = buyPrice;
    } else if (market === true && limit === false) {
      price = currentPrice;
    }

    if (secondCoinBalance == 0 || secondCoinBalance <= 0) {
      toast.error("Insufficient balance to execute this order");
      return false;
    }
    let submitData;
    if (InExtype === "include") {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
        percentAmt: buyPercentage,
      };
    } else {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
      };
    }
    setDisabledBuuButtn(true);

    let endPoint;
    if (orderMethod == "limit") {
      endPoint = "/frontapi/create_order";
    } else if (orderMethod === "market") {
      endPoint = "/frontapi/create_order_market";
    }

    setTimeout(() => setDisabledBuuButtn(false), 500);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + endPoint, submitData, config).then((resp) => {
      const apiResp = resp.data;
      sendMessageSocket();
      // subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
      if (apiResp.success === false) {
        toast.error(apiResp.message);
        setBuyAmount(0);
        setBuy_order_total(0);
        setOrder_id("");
        setFeeAmyt(0);
        setBPBtn(0);
        if (apiResp.data == 1) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        closeEditModel();
      } else {
        if (apiResp.message === "Failed to authenticate token.") {
          setTimeout(
            () => (window.location.href = "/login-to-buy-sell-crypto"),
            2000
          );
        } else {
          setIsEditModelOpen(false);
          toast.success(apiResp.message);
          setBuyAmount(0);
          setBuy_order_total(0);
          setOrder_id("");
          setFeeAmyt(0);
          setBPBtn(0);
        }
      }
    });
  };

  const sendMessageSocket = () => {
    const firstCoinName = params.firstCoin;
    const secondCoinName = params.secondCoin;

    const pairData = { firstCoin: firstCoinName, secondCoin: secondCoinName };
    clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
    clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
    clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
    handleSocketData(
      clientRef.current,
      clientRef.current.id,
      firstCoinName,
      secondCoinName
    );

    const token = localStorage.getItem("token");
    if (token) {
      subscribeToUserStreams(
        clientRef.current,
        params.firstCoin,
        params.secondCoin
      );
    }
  };

  const debouncedHandleBuySubmit = debounce(handleBuySubmit, 1000);

  const handleBuyChange = (event) => {
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var buyOrderTotal = "";
    if (name === "buy_order_total" && buyPrice > 0) {
      if (buy_commission !== null) {
        var commission = (value * buy_commission) / 100;
        var buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    } else {
      if (buy_commission !== null) {
        commission = (value * buy_commission) / 100;
        buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    }
    if (buyAmount == 0) {
      buyOrderTotal = (
        parseFloat(buy_value) * parseFloat(buy_order_total_write)
      ).toFixed(8);
      setBuy_order_total(buyOrderTotal);
    }
    if (name === "buyPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 9) {
          setBuyPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setBuyPrice(value);
        }
      }
    }
    if (name === "buyPrice" && buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyAmount);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyPrice(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        }
      }
    } else if (name === "buyAmount" && buyPrice > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyPrice);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyAmount(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        }
      }
    } else if (name === "buy_order_total" && buyPrice > 0) {
      let buyOrderTotal = (parseFloat(value) / parseFloat(buyPrice)).toFixed(8);
      let buyOrderTotalWithFee = parseFloat(buy_value);
      setBuy_order_total_write(buyOrderTotal);
      setBuyAmount(buyOrderTotal);
      setFeeAmyt(buyOrderTotalWithFee);
    }
  };

  const handleBuyPercent = (percentAmt) => {
    setBuyPercentage(percentAmt);
    var userBalance = secondCoinBalance;
    var percentAmts =
      (parseFloat(percentAmt).toFixed(8) * parseFloat(userBalance).toFixed(8)) /
      100;
    let Amount = percentAmts * (100 / (100 + buy_commission));
    let subTotal = Number(Amount).toFixed(8) / Number(buyPrice);
    setFeeAmyt(parseFloat(percentAmts).toFixed(8));
    setBuy_order_total(Amount.toFixed(8));
    setBuyAmount(subTotal.toFixed(8));
    setBPBtn(percentAmt);
    setInExtype("include");
  };

  const AuthAction = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_buy"
          type="submit"
          disabled={disabledBuuButtn}
        >
          Buy
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  const buyBoxHtml = () => {
    return (
      <div className="buy-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              Buy {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {secondCoin} :{" "}
              {secondCoinBalance ? formatDecimal(secondCoinBalance) : 0}
            </span>
          </div>
        </div>
        <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              Limit
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              Market
            </a>
          </li>
        </ul>
        <form
          name="buyForm"
          onSubmit={handleBuySubmit}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs ">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceBuy}
                    onChange={handleBuyChange}
                    name="stopPriceBuy"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">Price: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="pric"
                      value="Market"
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_buy"
                      value={disabledButton === true ? currentPrice : buyPrice}
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">Amount: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="buyAmount"
                  onChange={handleBuyChange}
                  value={buyAmount}
                  id="buyAmount"
                  aria-invalid="true"
                  onKeyPress={handleKeyPress}
                />
                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${bPBtn == 25 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${bPBtn == 50 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${bPBtn == 75 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${bPBtn == 100 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">Total: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_buy"
                      onChange={handleBuyChange}
                      name="buy_order_total"
                      value={formatDecimal(buy_order_total)}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}
              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Trade Fee :{" "}
                  {buy_commission == null ? 0 : formatDecimal(buy_commission)}
                  {buy_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Net Amount : {feeAmyt ? formatDecimal(feeAmyt) : 0}
                </p>
              </div>
            </div>
            {AuthAction()}
          </div>
        </form>
      </div>
    );
  };

  const handleSellSubmit = (event) => {
    event.preventDefault();

    if (sellPrice < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (sellAmount < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (sellPrice <= 0) {
      toast.error("Please enter valid price");
      return false;
    }

    var price = "";
    if (limit === true) {
      price = sellPrice;
    }
    if (market === true) {
      price = currentPrice;
    }

    if (firstCoinBalance == 0 || firstCoinBalance <= 0) {
      toast.error("Insufficient balance to execute this order");
      return false;
    }

    const submitData = {
      orderType: "sell",
      per_price: price,
      volume: sellAmount,
      firstCoinId: firstCoinId,
      secondCoinId: secondCoinId,
      sellOrderId: sell_order_id,
      sellFeeAmyt: sellFeeAmyt,
      orderMethod: orderMethod,
      buy_commission: sell_commission,
    };
    setDisabledSellButtn(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/create_order", submitData, config)
      .then((resp) => {
        const apiResp = resp.data;
        sendMessageSocket();
        if (apiResp.success === false) {
          closeEditModel();
          toast.error(apiResp.message);
          setBuyAmount(0);
          setBuy_order_total(0);
          setBuy_order_total_write(0);
          setOrder_id("");
          setSell_order_id("");
          setSellFeeAmyt(0);
          setSPBtn(0);
          if (apiResp.data == 1) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
          setDisabledSellButtn(false);
        } else {
          setIsEditModelOpenSell(false);
          toast.success(apiResp.message);
          setSellAmount(0);
          setSell_order_total(0);
          setOrder_id("");
          setSell_order_id("");
          setSellFeeAmyt("");
          setSPBtn(0);
          setDisabledSellButtn(false);
        }
      });
  };

  const handleSellChange = (event) => {
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var sellOrderTotal = "";
    if (name === "sell_order_total" && sellPrice > 0) {
      let commission = value * (100 / (100 + sell_commission));
      var sell_value = parseFloat(commission);
    } else {
      let commission = value * (100 / (100 + sell_commission));
      sell_value = commission;
    }
    if (name === "sellPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setSellPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setSellPrice(value);
        }
      }
    }
    if (name === "sellPrice" && sellAmount > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellAmount);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellPrice(formattedValue);
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellAmount)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sellAmount" && sellPrice > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellPrice);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellAmount(formattedValue);
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellPrice)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sell_order_total" && sellPrice > 0) {
      let sellOrderTotalWithFee = parseFloat(sell_value);
      let sellOrderTotal = (parseFloat(value) / parseFloat(sellPrice)).toFixed(
        8
      );
      setSellAmount(sellOrderTotal);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
  };

  const handleSellPercent = (percentAmt) => {
    var userBalance = firstCoinBalance;
    var percentAmts = (percentAmt * userBalance) / 100;
    percentAmts = percentAmts.toFixed(8);
    let finalAmt = parseFloat(percentAmts) * parseFloat(sellPrice);
    let Amount = percentAmts * (100 / (100 + sell_commission));
    let subTotal = Number(Amount) * Number(sellPrice);
    setSellFeeAmyt(subTotal.toFixed(8));
    setSell_order_total(finalAmt.toFixed(8));
    setSellAmount(parseFloat(percentAmts).toFixed(8));
    setSPBtn(percentAmt);
  };

  const AuthActionSell = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_sell"
          disabled={disabledSellButtn}
        >
          Sell
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border3 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  const sellBoxHtml = () => {
    return (
      <div className="sell-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              Sell {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {firstCoin} :{" "}
              {firstCoinBalance ? formatDecimal(firstCoinBalance) : 0}{" "}
            </span>
          </div>
        </div>
        <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              Limit
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              Market
            </a>
          </li>
        </ul>
        <form
          name="buyForm"
          onSubmit={handleSellSubmit}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs  ">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceSell}
                    onChange={handleSellChange}
                    name="stopPriceSell"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">Price: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="price"
                      value="Market"
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_sell"
                      value={disabledButton === true ? currentPrice : sellPrice}
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">Amount: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="sellAmount"
                  id="amount_sell"
                  onChange={handleSellChange}
                  value={sellAmount}
                  onKeyPress={handleKeyPress}
                />

                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${sPBtn == 25 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${sPBtn == 50 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${sPBtn == 75 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${sPBtn == 100 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">Total: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_sell"
                      name="sell_order_total"
                      value={formatDecimal(sell_order_total)}
                      onChange={handleSellChange}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}

              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Trade Fee :{" "}
                  {sell_commission == null ? 0 : formatDecimal(sell_commission)}{" "}
                  {sell_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Net Amount : {sellFeeAmyt ? formatDecimal(sellFeeAmyt) : 0}
                </p>
              </div>
            </div>
            {AuthActionSell()}
          </div>
        </form>
      </div>
    );
  };

  const handlePriceChangeChange = (value, side) => {
    if (buy_commission !== null && isFeeApply !== "Y" && isFeeEnable !== "1") {
      var commission = (buyAmount * buy_commission) / 100;
      var buy_value = parseFloat(buyAmount) + parseFloat(commission);
    } else {
      buy_value = buyAmount;
    }
    if (buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(value);
      var price = value * buyAmount;
      setBuy_order_total(price);
      setFeeAmyt(buyOrderTotalWithFee);
    }
    if (sellAmount > 0) {
      let commission =
        sellAmount *
        (100 /
          (100 +
            (isFeeApply !== "Y" && isFeeEnable !== "1"
              ? sell_commission
              : "")));
      let sell_value = commission;
      let sellOrderTotalWithFee = parseFloat(sell_value) * parseFloat(value);
      var sellPriceNew = value * sellAmount;
      setSell_order_total(sellPriceNew);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
    setBuyPrice(value);
    setSellPrice(value);
  };

  const formatNumber = (number) => {
    return number
      .toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 20,
      })
      .replace(/\.?0+$/, "");
  };

  const handlePriceChangeChangeBinance = (value) => {
    setBuyPrice(parseFloat(value.replace(/,/g, "")));
    setSellPrice(parseFloat(value.replace(/,/g, "")));
  };

  const activeSellOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.asks) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };
        const sortedAsks = binanceDepth.asks.sort((a, b) => b[0] - a[0]);

        console.log(sortedAsks,"sortedAsks")
        let asksToShow = sortedAsks.slice(5);
        console.log(asksToShow,"asksToShow")
        const maxQuantity = binanceDepth.asks[0][1];
        const tableRows = asksToShow
          .slice(0, 15)
          .map((activeSellOrderSingle, index) => {
            const tradePrice = parseFloat(activeSellOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarStyle = {
              width: calculateProgressBarWidth(
                activeSellOrderSingle[1],
                maxQuantity
              ),
            };
            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding magenta crypt-down">
                  <span className="tr_prograss" style={progressBarStyle}></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatDecimal(activeSellOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeSellOrderSingle &&
                    formatDecimal(
                      activeSellOrderSingle[0] * activeSellOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeSellOrder) {
        collectHtml = [];
        activeSellOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "sell")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                {formatDecimal(item.per_price)}
              </td>
              <td className="f-left ng-binding ">
                {formatDecimal(item.sell_spend_amount)}
              </td>
              <td className="f-left ng-binding text-right">
                {formatDecimal(item.per_price * item.sell_spend_amount)}
              </td>
            </tr>
            // </a>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Data Found
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const sideLastHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        return (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {formatDecimal(livPrice)}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      } else {
        var topBarData = topBarApiData;
        var collectHtml = (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {formatDecimal(topBarData.currentPrice)}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const activeBuyOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.bids) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };

        const maxQuantity = binanceDepth.bids[0][1];

        console.log(binanceDepth,"binanceDepth")
        const tableRows = binanceDepth.bids
          .slice(0, 15)
          .map((activeBuyOrderSingle, index) => {
            const tradePrice = parseFloat(activeBuyOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarWidth = calculateProgressBarWidth(
              activeBuyOrderSingle[1],
              maxQuantity
            );

            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${progressBarWidth}` }}
                  ></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatDecimal(activeBuyOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeBuyOrderSingle &&
                    formatDecimal(
                      activeBuyOrderSingle[0] * activeBuyOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeBuyOrder) {
        collectHtml = [];
        activeBuyOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "buy")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding green crypt-up">
                {formatDecimal(item.per_price)}
              </td>
              <td className="f-left ng-binding ">
                {formatDecimal(item.buy_get_amount)}
              </td>
              <td className="f-left ng-binding text-right">
                {formatDecimal(item.per_price * item.buy_get_amount)}
              </td>
            </tr>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Data Found
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const marketTradeHtml = () => {
    if (exchange === "binance") {
      if (binanceTrades) {
        return (
          <tbody>
            <MarketTrade
              trades={binanceTrades}
              record={[]}
              currentPair={params.firstCoin + "/" + params.secondCoin}
            />
          </tbody>
        );
      }
    } else {
      if (marketTrade) {
        const collectHtml = [];
        for (var i = 0; i < marketTradeData.length; i++) {
          const singleData = marketTradeData[i];
          var setColor = singleData.extype === "buy" ? "green" : "magenta";
          var getTime = Moment(singleData.created_at).format("lll");
          collectHtml.push(
            <tr className="ng-scope" key={singleData.id}>
              <td className={"f-left ng-binding " + setColor}>
                {formatDecimal(singleData.get_per_price)}
              </td>
              <td className="f-left ng-binding">
                {formatDecimal(singleData.get_amount)}
              </td>
              <td className="f-left ng-binding">
                {singleData.firstCoin + "/" + singleData.secondCoin}
              </td>
              <td className="f-left ng-binding">{getTime}</td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No data avaliable
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const updateBuyOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setUpdateAndDeleteButton(true);

    axios
      .post(baseUrl + "/frontapi/get_order", submitData, config)
      .then((resp) => {
        var apiResp = resp.data;
        setTimeout(() => {
          setUpdateAndDeleteButton(false);
        }, 2000);

        if (secondCoinBalance == 0 || secondCoinBalance == null) {
          setInExtype("include");
        }

        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          var orderData = apiResp.data[0];
          setIsEditModelOpen(true);
          setDisabledBuuButtn(true);
          setBuyPrice(parseFloat(orderData.per_price));
          setBuyAmount(orderData.total_buy_get_amount);
          setOrder_id(orderData.id);
          setFirstCoinNew(orderData.name1);
          setFeeAmyt(orderData.net_amount);
          setInExtype(orderData.fees_type);
          if (orderData.total_buy_get_amount == 1) {
          } else {
            setBuyAmount(orderData.total_buy_get_amount);
          }
          toast.success(apiResp.message);
        }
      });
  };

  const deleteOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setUpdateAndDeleteButton(true);
    setTimeout(() => {
      setUpdateAndDeleteButton(false);
    }, 1000);
    axios
      .post(baseUrl + "/frontapi/delete_order", submitData, config)
      .then((resp) => {
        sendMessageSocket();
        var apiResp = resp.data;
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          toast.success(apiResp.message);
        }
      });
  };

  const myActiveBuyOrderHtml = () => {
    if (myActiveBuyOrder) {
      var collectHtml = [];

      myActiveBuyOrderData.forEach((e, i) => {
        const parsedDate = Moment(e.created_at);
        var getTime = parsedDate.format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {formatDecimal(e.per_price)}
            </td>
            <td className="f-left ng-binding">
              {formatDecimal(e.buy_get_amount)}
            </td>
            <td className="f-left ng-binding">
              {formatDecimal(e.per_price * e.buy_get_amount)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  disabled={updateAndDeleteButton}
                  onClick={() => updateBuyOrder(e.buy_id, "buy")}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                disabled={updateAndDeleteButton}
                onClick={() => deleteOrder(e.buy_id, "buy")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedBuyOrderHtml = () => {
    if (myCompletedBuyOrder) {
      const collectHtml = [];
      for (var i = 0; i < 10; i++) {
        const myCompletedBuyOrderSingle = myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "lll"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {formatDecimal(myCompletedBuyOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding">
                {formatDecimal(myCompletedBuyOrderSingle.total_buy_get_amount)}
              </td>
              <td className="f-left ng-binding">
                {formatDecimal(
                  myCompletedBuyOrderSingle.per_price *
                    myCompletedBuyOrderSingle.total_buy_get_amount
                )}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const updateSellOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/get_order", submitData, config)
      .then((resp) => {
        var apiResp = resp.data;
        if (firstCoinBalance == 0 || firstCoinBalance == null) {
          setInExtype("include");
        }
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          var orderData = apiResp.data[0];
          setIsEditModelOpenSell(true);
          setDisabledSellButtn(true);
          setSellPrice(orderData.per_price);
          setSellAmount(orderData.total_sell_spend_amount);
          setSell_order_id(orderData.id);
          setFirstCoinNew(orderData.name1);
          setSellFeeAmyt(orderData.net_amount);

          if (orderData.total_sell_spend_amount == 1) {
            setSellAmount(orderData.total_sell_spend_amount);
          }
          toast.success(apiResp.message);
        }
      });
  };

  const myActiveSellOrderHtml = () => {
    if (myActiveSellOrder) {
      var collectHtml = [];
      myActiveSellOrderData.forEach((e, i) => {
        var getTime = Moment(e.created_at).format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {formatDecimal(e.per_price)}
            </td>
            <td className="f-left ng-binding">
              {formatDecimal(e.sell_spend_amount)}
            </td>
            <td className="f-left ng-binding">
              {formatDecimal(e.per_price * e.sell_spend_amount)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  onClick={() => updateSellOrder(e.sell_id, "sell")}
                  disabled={updateAndDeleteButton}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                onClick={() => deleteOrder(e.sell_id, "sell")}
                disabled={updateAndDeleteButton}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        );
      });
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedSellOrderHtml = () => {
    if (myCompletedSellOrder) {
      const collectHtml = [];
      for (var i = 0; i < 10; i++) {
        const myCompletedSellOrderSingle = myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = Moment(myCompletedSellOrderSingle.created_at).format(
            "lll"
          );

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {formatDecimal(myCompletedSellOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding">
                {formatDecimal(
                  myCompletedSellOrderSingle.total_sell_spend_amount
                )}
              </td>
              <td className="f-left ng-binding">
                {formatDecimal(
                  myCompletedSellOrderSingle.per_price *
                    myCompletedSellOrderSingle.total_sell_spend_amount
                )}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  var tempData = binanceTicker;
  if (exchange === "binance") {
    var titleData =
      new BigNumber(tempData.c).toFormat(null, 1) +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      tempData.s +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName}  Exchange`;
    if (titleData == `NaN  |  undefined  |  ${ProductName}  Exchange`) {
      titleData = "Crypto Buying Selling In India | Bitcoin Exchange Platform";
    }
  } else {
    titleData =
      currentPrice === null || currentPrice == ""
        ? "0" +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`
        : currentPrice > 1
        ? currentPrice.toLocaleString() +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`
        : currentPrice +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`;
  }

  const meta = {
    title: titleData.toString(),
    description: `${ProductName}  Exchange Live Is A Crypto Trading Platform That Exchanges Cryptocurrencies Like Bitcoin, Dogecoin, TRX, XRP, MRC etc. Sign Up For A New Account.`,
    canonical: `https://${ProductName} Exchange.com/high-limit-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <ExchangeKycHeader
          graphColor={changeTheme}
          portfolioData={portfolioData}
        />{" "}
        <div className="bghwhite">
          <div className="dashbord_manbox dashbord_manbox_das exchange-main-outer">
            <div className="container-fluid">
              <div className="dashbord_in1">
                <div className="dashbord_in dashbord_in_top fs12">
                  <div className="kline-para kline-para-basic d-flex align-items-center">
                    <div className="box-kline-para-basic-left">
                      <span className="productSymbol has-info ng-scope">
                        <strong className="ng-binding">
                          {token && (
                            <svg
                              viewBox="0 0 38 35"
                              width="20px"
                              height="20px"
                              onClick={favListApi}
                              style={{ cursor: "pointer" }}
                            >
                              <g id="Home" />
                              <g id="Print" />
                              <g id="Mail" />
                              <g id="Camera" />
                              <g id="Video" />
                              <g id="Film" />
                              <g id="Message" />
                              <g id="Telephone" />
                              <g id="User" />
                              <g id="File" />
                              <g id="Folder" />
                              <g id="Map" />
                              <g id="Download" />
                              <g id="Upload" />
                              <g id="Video_Recorder" />
                              <g id="Schedule" />
                              <g id="Cart" />
                              <g id="Setting" />
                              <g id="Search" />
                              <g id="Pencils" />
                              <g id="Group" />
                              <g id="Record" />
                              <g id="Headphone" />
                              <g id="Music_Player" />
                              <g id="Sound_On" />
                              <g id="Sound_Off" />
                              <g id="Lock" />
                              <g id="Lock_open" />
                              <g id="Love" />
                              <g id="Favorite">
                                <path
                                  d="M30.9,10.6C30.8,10.2,30.4,10,30,10h0h-9l-4.1-8.4C16.7,1.2,16.4,1,16,1v0c0,0,0,0,0,0   c-0.4,0-0.7,0.2-0.9,0.6L11,10H2c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l6.6,7.6L5,29.7c-0.1,0.4,0,0.8,0.3,1   s0.7,0.3,1.1,0.1l9.6-4.6l9.6,4.6C25.7,31,25.8,31,26,31h0h0h0c0.5,0,1-0.4,1-1c0-0.2,0-0.3-0.1-0.5l-2.8-10.3l6.6-7.6   C31,11.4,31.1,10.9,30.9,10.6z"
                                  fill="transparent"
                                  style={
                                    isActive === "N"
                                      ? { stroke: "#e1ae39" }
                                      : { stroke: "#e1ae39", fill: "#e1ae39" }
                                  }
                                />
                              </g>
                              <g id="Film_1_" />
                              <g id="Music" />
                              <g id="Puzzle" />
                              <g id="Turn_Off" />
                              <g id="Book" />
                              <g id="Save" />
                              <g id="Reload" />
                              <g id="Trash" />
                              <g id="Tag" />
                              <g id="Link" />
                              <g id="Like" />
                              <g id="Bad" />
                              <g id="Gallery" />
                              <g id="Add" />
                              <g id="Close" />
                              <g id="Forward" />
                              <g id="Back" />
                              <g id="Buy" />
                              <g id="Mac" />
                              <g id="Laptop" />
                            </svg>
                          )}
                          {firstCoin}
                        </strong>
                        <font className="ng-binding"> / {secondCoin}</font>
                      </span>
                    </div>
                    <div className="box-kline-para-basic-right">
                      {topBarHtml()}
                      <div className="cls"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 padding-right exchange_left ">
                      <div className="order-history-outer ne_box col-non-padding col-min-padding-right ">
                        <div className="panel panel-default">
                          <div className="panel-heading tab-box">
                            <ul className="exchange_tab">
                              {token && (
                                <li
                                  className={
                                    setUrl === "fav"
                                      ? "active tabQuoteAsset"
                                      : "tabQuoteAsset"
                                  }
                                  onClick={() => activeCurrency("fav")}
                                >
                                  <i className="fa fa-star"></i>
                                </li>
                              )}
                              {exchange_tab.map((list) => {
                                return (
                                  <li
                                    key={list.id}
                                    className={
                                      setUrl == list.short_name
                                        ? "active tabQuoteAsset"
                                        : "tabQuoteAsset"
                                    }
                                    onClick={() =>
                                      activeCurrency(list.short_name)
                                    }
                                  >
                                    {list.short_name}
                                  </li>
                                );
                              })}
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="panel-body p-0">
                            <div className="searchFilter f-cb">
                              <div className="search-box search-box-new">
                                <input
                                  type="text"
                                  id="search-int"
                                  placeholder=""
                                  className="ng-pristine ng-untouched ng-valid ng-empty"
                                  onChange={onSearch}
                                  value={searchedValue}
                                />
                                {searchedValue !== "" ? (
                                  <i
                                    onClick={EmptySearch}
                                    className="las la-times-circle"
                                  ></i>
                                ) : (
                                  <i className="las la-search"></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <table className="table mb-0 currency_table1">
                            <thead>
                              <tr>
                                <th
                                  className="f-left r-market-pair ng-binding"
                                  ng-click="sortByKey('baseAsset')"
                                >
                                  Pair
                                </th>
                                <th
                                  className="f-left r-market-price ng-binding"
                                  // onClick={() => sortCoin("price")}
                                  // style={{ cursor: "pointer" }}
                                >
                                  Price
                                  <span className="ng-scope">
                                    {/* {onPriceChange === true ? (
                                      <i className="fa fa-caret-down"></i>
                                    ) : (
                                      <i className="fa fa-caret-up"></i>
                                    )} */}
                                  </span>
                                </th>

                                <th
                                  className="r-market-rate ng-binding ng-scope tr"
                                  onClick={() => sortCoin("change")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Change
                                  <span className="ng-scope">
                                    {onChangeChange === true ? (
                                      <i className="fa fa-caret-down"></i>
                                    ) : (
                                      <i className="fa fa-caret-up"></i>
                                    )}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                          </table>
                          <div className="table-overflow">
                            <table className="table table-hover currency_table">
                              {coinListHtml(coinListData)}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 exchange_right">
                      <div className="ne_box p-0">
                        {/* {exchange == "" ? ( */}
                        <TradingViewChart
                          params={params}
                          graphColor={themeRefresh}
                        />
                        {/* ) : (
                          <div className="binance-chart-container">
                            {openTradingChart(firstCoin + secondCoin)}
                          </div>
                        )} */}

                        {/* <div className="binance-chart-container">
                            {
                              <BinanceChart
                                symbol={params.firstCoin + params.secondCoin}
                              />
                            }
                          </div> */}
                      </div>
                      <div className="dp_none status-outer">
                        <ul class="nav nav-tabs2 ">
                          <li class="nav-item">
                            <a
                              class="nav-link active m-2"
                              data-toggle="tab"
                              href="#buymobile"
                            >
                              Buy{" "}
                              {firstCoinNew != "" ? firstCoinNew : firstCoin}
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link m-2"
                              data-toggle="tab"
                              href="#sellmobile"
                            >
                              Sell{" "}
                              {firstCoinNew != "" ? firstCoinNew : firstCoin}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="row tab-content h306">
                        <div
                          className="col-md-6 tab-pane active"
                          id="buymobile"
                        >
                          <div className="ne_box h_100 ">{buyBoxHtml()}</div>
                        </div>
                        <div
                          className="col-md-6  tab-pane dp_block"
                          id="sellmobile"
                        >
                          <div className="ne_box h_100 ">{sellBoxHtml()}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="ne_box h_100 br_0">
                        <h6>Market Depth</h6>
                        <div style={{}}  className="table-responsive bs_border">
                          <table className="table mb-0 ">
                            <thead> 
                              <tr>
                                <th>Price</th>
                                <th>Qty.</th>
                                <th className="text-right">
                                  Total({secondCoin})
                                </th>
                              </tr>
                            </thead>
                            {activeSellOrderHtml()}
                          </table>
                        </div>
                        <h6 className="text-center t2t m-0">
                          {" "}
                          {sideLastHtml()}
                        </h6>
                        <div className="bs_border table-responsive ">
                          <table className="table mb-0 ">
                            <thead>
                              <tr>
                                <th>Price</th>
                                <th>Qty.</th>
                                <th className="text-right">
                                  Total({secondCoin})
                                </th>
                              </tr>
                            </thead>
                            {activeBuyOrderHtml()}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="color-background orderform-main">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="ne_box p-0 ">
                          <h6 className="h6_head pl-3  ">Spot Balance</h6>
                          <div className="balance-outer">
                            <table className="w-100">{showSpotBalance()}</table>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="ne_box h_100">
                          <div className="table_hadding flex-wrap ">
                            <h6 className="h6_head">Order</h6>
                            <div className="status-outer ">
                              <ul className="nav nav-tabs2" role="tablist">
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#Buytab"
                                    role="tab"
                                  >
                                    Buy
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#Selltab"
                                    role="tab"
                                  >
                                    Sell
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="buy-order-outer tab-content">
                            <div
                              className="tab-pane active"
                              id="Buytab"
                              role="tabpanel"
                            >
                              <div className="table_hadding flex-wrap table_hadding2">
                                <h6 className="h6_head">Buy Orders</h6>
                                <div className="status-outer">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#pending"
                                        role="tab"
                                      >
                                        Pending
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#complete"
                                        role="tab"
                                      >
                                        Completed
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="buy-order-outer tab-content">
                                <div
                                  className="tab-pane active"
                                  id="pending"
                                  role="tabpanel"
                                >
                                  <div className="userRecords-main obo_exchange">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th className="f-left ng-binding">
                                            Pair
                                          </th>
                                          <th className="f-left ng-binding">
                                            Price
                                          </th>
                                          <th className="f-left ng-binding">
                                            Amount
                                          </th>
                                          <th className="f-left ng-binding">
                                            Total
                                          </th>
                                          <th className="f-left ng-binding">
                                            Date & Time
                                          </th>
                                          <th className="f-center cancels">
                                            <span className="ng-binding p-0">
                                              Action
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      {myActiveBuyOrderHtml()}
                                    </table>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane"
                                  id="complete"
                                  role="tabpanel"
                                >
                                  <div className="userRecords-main">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th>Pair</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th>Total</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      {myCompletedBuyOrderHtml()}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="tab-pane "
                              id="Selltab"
                              role="tabpanel"
                            >
                              <div className="table_hadding flex-wrap table_hadding2">
                                <h6 className="h6_head">Sell Orders</h6>
                                <div className="status-outer">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#pending-sell"
                                        role="tab"
                                      >
                                        Pending
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#complete-sell"
                                        role="tab"
                                      >
                                        Completed
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="sell-order-outer tab-content">
                                <div
                                  className="tab-pane active"
                                  id="pending-sell"
                                  role="tabpanel"
                                >
                                  <div className="userRecords-main obo_exchange">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th className="f-left ng-binding">
                                            Pair
                                          </th>
                                          <th className="f-left ng-binding">
                                            Price
                                          </th>
                                          <th className="f-left ng-binding">
                                            Amount
                                          </th>
                                          <th className="f-left ng-binding">
                                            Total
                                          </th>
                                          <th className="f-left ng-binding">
                                            Date & Time
                                          </th>
                                          <th className="f-center cancels">
                                            <span className="ng-binding p-0">
                                              Action
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      {myActiveSellOrderHtml()}
                                    </table>
                                  </div>
                                </div>
                                <div
                                  className="tab-pane"
                                  id="complete-sell"
                                  role="tabpanel"
                                >
                                  <div className="userRecords-main">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th>Pair</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th>Total</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      {myCompletedSellOrderHtml()}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Modal
                        className="modify_order"
                        show={isEditModelOpen}
                        onHide={closeEditModel}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title> Modify Order</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <input
                            type="number"
                            className="form-control  mb-3"
                            name="editPrice"
                            value={buyPrice}
                            onChange={onEditOrder}
                          />
                          <input
                            type="number"
                            className="form-control  mb-3"
                            name="editAmount"
                            value={buyAmount}
                            onChange={onEditOrder}
                          />
                          <button
                            onClick={handleBuySubmit}
                            className="btn w100 my_color_buy"
                          >
                            Update
                          </button>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        className="modify_order"
                        show={isEditModelOpenSell}
                        onHide={closeEditModel}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title> Modify Order</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <input
                            type="number"
                            name="editPrice"
                            className="form-control mb-3"
                            value={sellPrice}
                            onChange={onEditOrderSell}
                          />
                          <input
                            type="number"
                            name="editAmount"
                            className="form-control  mb-3"
                            value={sellAmount}
                            onChange={onEditOrderSell}
                          />
                          <button
                            onClick={handleSellSubmit}
                            className="btn w100 my_color_sell"
                          >
                            Update
                          </button>
                        </Modal.Body>
                      </Modal>

                      <div className="col-md-3 ">
                        <div className="ne_box h_100 br_0">
                          <div className="market-trade">
                            <div className="tradehistory tradehistory-new ">
                              <div
                                className="item-con tradeHistory-base"
                                id="tradeHistory"
                              >
                                <h6 className="h6_head">Order History</h6>
                                <div className="scrollStyle ng-scope">
                                  <table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th>Price</th>
                                        <th>Amount</th>
                                        <th>Pair</th>
                                        <th>Date & Time</th>
                                      </tr>
                                    </thead>
                                    {marketTradeHtml()}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
          <div className="copy-right">
            <p>
              © 2024 Rubiex Exchange {/* {ProductName}*/}. All Rights Reserved{" "}
              <small>Build version 1.0.6</small>
            </p>
          </div>
        </div>
        <div className="footer2">
          <div className="container">
            <div className="row row5">
              <div className="col-3">
                <a href="#markets" className="active">
                  <i className="fa fa-list-ul"></i> Markets{" "}
                </a>
              </div>
              <div className="col-3">
                <a href="">
                  <i className="fa fa-line-chart"></i> Charts{" "}
                </a>
              </div>
              <div className="col-3">
                <a href="">
                  <span className="responsive_item_gmx">
                    <img
                      className="img-fluid"
                      src="/assets/img/logo-icon-main.png"
                      alt="coin"
                    />
                  </span>{" "}
                  Buy/Sell{" "}
                </a>
              </div>
              <div className="col-3">
                <a href="">
                  <i className="fa fa-history"></i> Trades{" "}
                </a>
              </div>
              <div className="col-3">
                <a href="">
                  <i className="fa fa-user"></i> My Orders{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </div>
  );
};

export default connect((state) => state)(ExchnageFuncSocket);
