// const mainUrl = "http://localhost:8000/frontapi",
const mainUrl = "https://api.itoex.io/frontapi",
  base = {
    whitelistData: "/whitelistData",
    deleteWhitelist: "/deleteWhiteAddress",
    whitelistAdd: "/addwhitelistaddress",
    cryptoCoinList: "/cryptocoin_list",
    userData: "/UserData",
    deleteWhiteList: "/deleteWhiteAddress",
    coinPairList: "/coin_pair_list",
  };

export { base, mainUrl };
