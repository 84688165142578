import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as myConstList from "../Common/BaseUrl";
import { toast } from 'react-toastify';
import { ImageValidation } from '../Validation/ImageValidation';
import { Apis, ThirdPartyApi } from './ThirdPartyApis';
import { panImageApi, panNumberApi } from './Apis';
import { ConsoleView } from 'react-device-detect';
import Rejectreason from './Rejectreason';
const baseUrl = myConstList.baseUrl;

const Pancard = (props) => {
    const [image, setImage] = useState("/assets/img/icon2.png")
    const [userUploadImage, setUserUploadImage] = useState("")
    const [panNumber, setPanNumber] = useState("")
    const [panNumberError, setPanNumberError] = useState("")
    const [panNumberStatus, setPanNumberStatus] = useState("N")
    const [panImageStatus, setPanImageStatus] = useState("0")
    const [pancardImage, setPancardImage] = useState("")
    const [pancardImageShow, setPancardImageShow] = useState("")
    const [pancardImageError, setPancardImageError] = useState("")
    const [pancardData, setPancardData] = useState([])
    const [rejectionReason, setRejectionReason] = useState("")
    const [btnDisable, setBtnDisable] = useState(false)


    useEffect(() => {
        if (props.userinfo.length > 0) {
            props.userinfo[0].i_image3 && setImage(baseUrl + "/static/upload/" + props.userinfo[0].i_image3)
            props.userinfo[0].panNumber && setPanNumber(props.userinfo[0].panNumber)
            setPanNumberStatus(props.userinfo[0].pan_number_status)
            setPanImageStatus(props.userinfo[0].image3_status)
            setRejectionReason(props.userinfo[0].d3_rejectResion)
        }

    }, [props])


    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value
        if (name == "panNumber") {
            // var regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            // var regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
            var regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            setPanNumber(value)
            if (!value) {
                setPanNumberError("This field is required")
                return false
            }
            if (value.replace(/\s+/g, '').length == 0) {
                setPanNumberError("Please enter a valid account number")
                return false
            }
            if(!regex.test(value)){
                setPanNumberError("Please enter a valid pan number")
                return false
            }
            setPanNumberError("")
        }
        if (name == "panImage") {
            let error = ImageValidation("Pan card", e.target.files[0])
            setPancardImageError(error)
            e.target.files[0] ? setPancardImageShow(URL.createObjectURL(e.target.files[0])) :
                setPancardImageShow("")
            setPancardImage(e.target.files[0]);
            setPancardData(e.target.files);
        }
    }
    const handleNumberSubmit = async () => {
        if (!panNumber) {
            setPanNumberError("This field is required")
            return false
        }
        if (panNumber.replace(/\s+/g, '').length == 0) {
            setPanNumberError("Please enter a valid account number")
            return false
        }
        // if (panNumber && panNumberError) {
        //     setPanNumberError("")
        // }
        if (!panNumber && panNumberError) {
            return false
        }
        if(panNumberError){
            return false
        }
        setBtnDisable(true)
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let DocNumber = {
            pancard: panNumber,
        };
        let response = await panNumberApi(DocNumber, config)
        setTimeout(() => {
            setBtnDisable(false)
        }, 500);
        if (response.status) {
            toast.success("Pancard number updated Successfully ")
            props.resendResult(true)
        }
        else {
            toast.error(response.message)
            props.resendResult(true)
        }
    }
    const handleSubmit = async () => {
        if (panNumberStatus == "N") {
            // setPanNumberError("Please save pan number first")
            toast.error("Please save pan number first")
            return false
        }
        if (pancardImageError) {
            return false
        }
        if (!pancardImage) {
            setPancardImageError("This field is required")
            return false
        }
        setBtnDisable(true)
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        var formdata = new FormData();
        formdata.append('file', pancardImage);
        let responses = await panImageApi(formdata, config)
        setTimeout(() => {
            setBtnDisable(false)
        }, 500);
        if (responses.status) {
            toast.success("Pan card image uploaded successfully")
            props.resendResult(true)
        }
        else {
            toast.error(responses.error)
            props.resendResult(true)
        }
    }
    return (

        <>
            <div className='pan-outer'>
                <div className='row'>
                    <div className='col-md-3 col-12'>
                    </div>
                    <div className='col-md-9 col-12'>
                        <div className="white_box">
                            <h6 className="position-relative">
                                Pan Card Verification
                            </h6>
                            <b className="mt-0">Requirements</b>
                            <ul className="dit_ul mb-4">
                                <li>
                                    The photo/scan must be clear, in color and
                                    uncompressed.
                                </li>
                                <li>
                                    The photo/scan must be not edited and not cropped.
                                </li>
                                <li>
                                    The document must be valid at least 3 months after
                                    the verification submission date.
                                </li>
                                <li>
                                    The full name and date of birth you provided in
                                    the "Personal information" section{" "}
                                </li>
                                <li>
                                    should exactly match the ones in your document.
                                </li>
                                <li>Min size 5 Kb / Max size 5 Mb.</li>
                            </ul>
                            <div className="row">
                                <div className="form-group col-md-6 col-12">
                                    <label>Pan card number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Pan Card Number"
                                        name="panNumber"
                                        value={panNumber}
                                        onChange={handleChange}
                                        disabled={panNumberStatus == "Y" ? true : false}
                                    />
                                    {
                                        panNumberError &&
                                        <div className='text-danger'>{panNumberError}</div>
                                    }
                                    {panNumberStatus == "N" && <div className="save-btn">
                                        <button
                                            className="btn btn_man mt-3"
                                            onClick={handleNumberSubmit}
                                            disabled={btnDisable}
                                        >
                                          {btnDisable? "Saved":"Save"}
                                        </button>
                                    </div>}
                                </div>
                                <div className="form-group col-md-6 col-12"></div>
                                <div className="form-group col-md-6 col-12">
                                    <div
                                        id="file-upload-form"
                                        className="uploader"
                                    >
                                        <input
                                            type="file"
                                            id="file-upload3"
                                            name="panImage"
                                            onChange={handleChange}
                                            disabled={panImageStatus !== "0" && panImageStatus !== "3" ? true : false}
                                        />
                                        <label for="file-upload3" id="file-drag">
                                            <img
                                                src={
                                                    pancardImageShow !== ""
                                                        ? pancardImageShow
                                                        : image
                                                }
                                                className="img-fluid22"
                                                alt="Identity"
                                            />
                                            <div id="start">
                                                <span className="fasi"></span>
                                                <div>Upload Pan Card Document</div>
                                                <div id="notimage" className="hidden">
                                                    Please select an image
                                                </div>
                                            </div>
                                            <div id="response" className="hidden">
                                                <div id="messages"></div>
                                                <progress
                                                    className="progress"
                                                    id="file-progress"
                                                    value="0"
                                                >
                                                    <span>0</span>%
                                                </progress>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="mt-0">
                                        *only jpeg, jpg & png files supported
                                    </p>
                                    <Rejectreason reason={rejectionReason} status={panImageStatus} />
                                    {
                                        pancardImageError &&
                                        <div className='text-danger'>{pancardImageError}</div>
                                    }
                                </div>

                            </div>

                            {panImageStatus !== "2" && <button
                                className="btn btn_man w100px"
                                onClick={handleSubmit}
                                disabled={btnDisable || panImageStatus !== "0" && panImageStatus !== "3"}
                            >
                               {btnDisable || panImageStatus !== "0" && panImageStatus !== "3"?"Submited":"Submit"} 
                            </button>}

                            {/* {this.imageStatushtml3()} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pancard